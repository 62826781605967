/**
 * Fallback country
 * @memberof constants/AnalyticsConstants
 */
export const FALLBACK_NA = 'n/a';

/**
 * Fallback favourote error modal
 * @memberof constants/AnalyticsConstants
 */
export const FALLBACK_FAV_ERROR = 'favorite error modal';

/**
 * Fallback continue
 * @memberof constants/AnalyticsConstants
 */
export const CONTINUE = 'continue';

/**
 * Fallback continue
 * @memberof constants/AnalyticsConstants
 */
export const REMOVE_ITEMS_CONTINUE = 'Remove items and continue';

/**
 * Fallback view all
 * @memberof constants/AnalyticsConstants
 */
export const VIEWALL = 'view all';

/**
 * Fallback best seller
 * @memberof constants/AnalyticsConstants
 */
export const BESTSELLER = 'best sellers';

/**
 * Fallback favorite
 * @memberof constants/AnalyticsConstants
 */
export const FAVORITE = 'favorites';

/**
 * Fallback category
 * @memberof constants/AnalyticsConstants
 */
export const CATEGORY = 'category';

/**
 * Fallback ErrorMessage
 * @memberof constants/AnalyticsConstants
 */
export const ERROR_MESSAGE = 'errorMessage';

/**
 * Fallback menu click
 * @memberof constants/AnalyticsConstants
 */
export const MENU_CLICK = 'menu_click_action';

/**
 * Fallback WarningMessage
 * @memberof constants/AnalyticsConstants
 */
export const WARNING_MESSAGE = 'warningMessage';

/**
 * Fallback done
 * @memberof constants/AnalyticsConstants
 */
export const DONE = 'done';

/**
 * Fallback edit_ingredient
 * @memberof constants/AnalyticsConstants
 */
export const EDIT_INGREDIENT = 'edit_ingredient';

/**
 * Fallback remove
 * @memberof constants/AnalyticsConstants
 */
export const REMOVE = 'remove';

/**
 * Fallback  CANCEL
 * @memberof constants/AnalyticsConstants
 */
export const CANCEL = 'cancel';

/**
 * Fallback  CANclear allCEL
 * @memberof constants/AnalyticsConstants
 */
export const CLEAR_ALL = 'clear all';
/**
 * Fallback  pdp_ingredient_clear
 * @memberof constants/AnalyticsConstants
 */
export const PDP_INGREDIENT_CLEAR = 'pdp_ingredient_clear';

/**
 * Fallback  add to cart
 * @memberof constants/AnalyticsConstants
 */
export const ADD_TO_BAG_LABEL = 'add to bag';

/**
 * Fallback  make it a combo
 * @memberof constants/AnalyticsConstants
 */
export const MAKE_IT_A_COMBO = 'make it a combo';
/**
 * Fallback  make it a meal
 * @memberof constants/AnalyticsConstants
 */
export const MAKE_IT_A_MEAL = 'make it a meal';

/**
 * Fallback  regular
 * @memberof constants/AnalyticsConstants
 */
export const REGULAR = 'regular';

/**
 * Fallback  update
 * @memberof constants/AnalyticsConstants
 */
export const UPDATE = 'update';

/**
 * Fallback  updateToCart
 * @memberof constants/AnalyticsConstants
 */
export const UPDATE_TO_CART = 'updateToCart';
/**
 * Fallback  addToBag
 * Fallback order summary Message
 * @memberof constants/AnalyticsConstants
 */
export const ORDERSUMMARY = 'order summary';

/**
 * Fallbacklogin and continue Message
 * @memberof constants/AnalyticsConstants
 */
export const LOGINCONTINUE = 'login and continue';

/**
 * Fallback guest checkout Message
 * @memberof constants/AnalyticsConstants
 */
export const GUESTCHECKOUT = 'guest checkout';

export const CONTINUETOPAYMENT = 'continue to payment';
/**
 * Fallbackcart Continue as Guest Message
 * @memberof constants/AnalyticsConstants
 */
export const CONTINUEASGUEST = 'continue as guest';

/**
 * Fallbackcart continue Message
 * @memberof constants/AnalyticsConstants
 */
export const CARTCONTINUE = 'cart continue';

/**
 * Fallback remove miaaam Message
 * @memberof constants/AnalyticsConstants
 */
export const REMOVE_MIAM = 'remove miam';

/**
 * Fallback update to cart Message
 * @memberof constants/AnalyticsConstants
 */
export const UPDATE_CART = 'updateToCart';

/**
 * Fallback remove combo Message
 * @memberof constants/AnalyticsConstants
 */
export const REMOVE_COMBO = 'remove combo';

/**
 * Fallback empty cart Message
 * @memberof constants/AnalyticsConstants
 */
export const EMPTY_CART_CTA = 'empty_cart_cta';

/**
 * Fallback out of stock modal Message
 * @memberof constants/AnalyticsConstants
 */
export const OUT_OF_STOCK_MODAL = 'out of stock modal';

/**
 * Fallback empty cart Message
 * @memberof constants/AnalyticsConstants
 */
export const REMOVE_FROM_CART = 'remove from cart';

/**
 * Fallback remove from bag
 * @memberof constants/AnalyticsConstants
 */
export const REMOVE_FROM_BAG = 'remove from bag';

/**
 * Fallback promo code apply Message
 * @memberof constants/AnalyticsConstants
 */
export const REMOVE_OFFER_REWARD = 'offer-reward_remove';

/**
 * Fallback promo code apply Message
 * @memberof constants/AnalyticsConstants
 */
export const PROMO_CODE_APPLY = 'promo code apply';

/**
 * Fallback bag Message
 * @memberof constants/AnalyticsConstants
 */
export const BAG = 'bag';

/**
 * Fallback "cart promo code apply Message
 * @memberof constants/AnalyticsConstants
 */
export const CART_PROMO_CODE_APPY = 'cart promo code apply';
/**
 * Fallback upsell modal Message
 * @memberof constants/AnalyticsConstants
 */
export const UPSELL_MODAL = 'upsell modal';

/**
 * Fallback modal_pageview Message
 * @memberof constants/AnalyticsConstants
 */
export const MODAL_PAGEVIEW = 'modal_pageview';

/**
 * Fallback cart cta Message
 * @memberof constants/AnalyticsConstants
 */
export const CART_CTA = 'cart cta';

/**
 * Fallback product edit Message
 * @memberof constants/AnalyticsConstants
 */
export const PRODUCT_EDIT = 'product edit';

/**
 * Fallback addToBag Message
 * @memberof constants/AnalyticsConstants
 */
export const ADD_TO_BAG = 'addToBag';

/**
 * Fallback  PDP
 * @memberof constants/AnalyticsConstants
 */
export const ANALYTICS_PDP = 'PDP';

/**
 * Fallback  combo_screen_view
 * @memberof constants/AnalyticsConstants
 */
export const COMBO_SCREEN_VIEW = 'combo_screen_view';
/**
 * Fallback pageProducts Message
 * @memberof constants/AnalyticsConstants
 */
export const PAGE_PRODUCT = 'pageProducts';

/**
 * Fallback Cart_PageView Message
 * @memberof constants/AnalyticsConstants
 */
export const CART_PAGEVIEW = 'Cart_PageView';

/**
 * Fallback decrease quantity Message
 * @memberof constants/AnalyticsConstants
 */
export const DECREASE_QUANTITY = 'decrease quantity';

/**
 * increase quantity Message
 * @memberof constants/AnalyticsConstants
 */
export const INCREASE_QUANTITY = 'increase quantity';
/**
 * remove from cart
 * @memberof constants/AnalyticsConstants
 */
export const REMOVE_ITEM = 'remove';

/**
 * Fallback  select a drink
 * @memberof constants/AnalyticsConstants
 */
export const SELECT_A_DRINK = 'select a drink';

/**
 * Fallback  meal_screen_view
 * @memberof constants/AnalyticsConstants
 */
export const MEAL_SCREEN_VIEW = 'meal_screen_view';

/**
 * Fallback  combo_click_action
 * @memberof constants/AnalyticsConstants
 */
export const COMBO_CLICK_ACTION = 'combo_click_action';

/**
 * Fallback  miam_click_action
 * @memberof constants/AnalyticsConstants
 */
export const MIAM_CLICK_ACTION = 'miam_click_action';

/**
 * Fallback  side
 * @memberof constants/AnalyticsConstants
 */
export const SIDE = 'side';

/**
 * Fallback  select side
 * @memberof constants/AnalyticsConstants
 */
export const SELECT_SIDE = 'select side';

/**
 * Fallback   PR
 * @memberof constants/AnalyticsConstants
 */
export const PR = 'PR';

/**
 * Fallback favroite order source
 * @memberof constants/AnalyticsConstants
 */
export const FAVORITE_SOURCE = 'favorite';

/**
 * wraps the analytics call
 * @memberof constants/AnalyticsConstants
 */
export const BREAD_WRAPS = 'wrap';

/**
 * breakfast order source
 * @memberof constants/AnalyticsConstants
 */
export const BREAKFAST = 'breakfast';

/**
 * desayunos order source
 * @memberof constants/AnalyticsConstants
 */
export const DESAYUNOS = 'desayunos';

/**
 * pasta order source
 * @memberof constants/AnalyticsConstants
 */
export const PASTA = 'pasta';

export const ITEMS_ADDED = 'item(s) added to your bag';

/**
 * pasta curbside
 * @memberof constants/AnalyticsConstants
 */
export const ITEMS_REMOVED = 'item(s) removed to your bag';
/**
 * pasta curbside
 * @memberof constants/AnalyticsConstants
 */
export const CURBSIDE = 'curbside';

/**
 * Fallback floating header
 * @memberof constants/AnalyticsConstants
 */
export const FLOATING_HEADER = 'floating header';

/**
 * Fallback pdp_toppings
 * @memberof constants/AnalyticsConstants
 */
export const PDP_TOPPINGS = 'pdp_toppings';

/**
 * Fallback PDP_pageView
 * @memberof constants/AnalyticsConstants
 */
export const PDP_PAGEVIEW = 'PDP_pageView';

/**
 * Fallback expand
 * @memberof constants/AnalyticsConstants
 */
export const EXPAND = 'expand';
/**
 * Fallback collapse
 * @memberof constants/AnalyticsConstants
 */
export const COLLAPSE = 'collapse';

/**
 * Fallback reward removed
 * @memberof constants/AnalyticsConstants
 */
export const REWARD_REMOVED = 'reward removed';

/**
 * Fallback Yes, clear my cart
 * @memberof constants/AnalyticsConstants
 */
export const CLEARMYBAG = 'Yes, clear my bag';

/**
 * Fallback order something else
 * @memberof constants/AnalyticsConstants
 */
export const ORDERSOMETHINGELSE = 'order something else';

/**
 * Fallback remove items from bag
 * @memberof constants/AnalyticsConstants
 */
export const REMOVEITEMFROMCART = 'remove items from bag';
/**
 * Fallback empty_cart_pageView
 * @memberof constants/AnalyticsConstants
 */
export const EMPTY_CART_PAGEVIEW = 'empty_cart_pageView';

/**
 * Fallback shop the menu
 * @memberof constants/AnalyticsConstants
 */
export const SHOP_THE_MENU = 'shop the menu';

/**
 * Fallback empty bag
 * @memberof constants/AnalyticsConstants
 */
export const EMPTYBAG = 'empty bag';

/**
 * Curbside vehicle selection modal component name
 * @memberof constants/AnalyticsConstants
 */
export const VEHICLE_MODAL = 'vehicle selection modal';

/**
 * Curbside vehicle selection modal virtual name
 * @memberof constants/AnalyticsConstants
 */
export const CURBSIDE_POPUP = 'curbside vehicle selection modal';

/**
 * Fallback join / sign in
 * @memberof constants/AnalyticsConstants
 */
export const JOIN_SIGN_IN = 'join / sign in';

/**
 * Fallback keep shopping
 * @memberof constants/AnalyticsConstants
 */
export const KEEP_SHOPING = 'keep shopping';

/**
 * Analytics - No, thanks cta
 * @memberof constants/AnalyticsConstants
 */
export const NO_THANKS_CTA = 'no, thanks';

/**
 * Analytics - close
 * @memberof constants/AnalyticsConstants
 */
export const CLOSE_ICON = 'close';

/**
 * Analytics - add to bag cta
 * @memberof constants/AnalyticsConstants
 */
export const ADD_TO_BAG_CTA = 'add to bag';

/**
 * Analytics - terms and conditions link
 * @memberof constants/AnalyticsConstants
 */
export const TEMS_AND_CONDITIONS = 'terms and conditions';

/**
 * Analytics - switch to pickup cta
 * @memberof constants/AnalyticsConstants
 */
export const SWITCH_TO_PICKUP = 'switch to pickup';

/**
 * Analytics - switch to pickup cta
 * @memberof constants/AnalyticsConstants
 */
export const KEEP_DELIVERY = 'cancel and keep delivery';
/**
 * Analytics - switch to pickup cta
 * @memberof constants/AnalyticsConstants
 */
export const CONTINUE_WITH_ORDER = 'continue with order';

/**
 * Fallback  cart_click_action
 * @memberof constants/AnalyticsConstants
 */
export const CART_CLICK_ACTION = 'cart_click_action';

/**
 * Fallback  view my rewards cta
 * @memberof constants/AnalyticsConstants
 */
export const VIEW_MY_REWARDS = 'view my rewards';

/**
 * Fallback  yellow sticket title
 * @memberof constants/AnalyticsConstants
 */
export const YELLOW_STICKER = 'yellow sticker';
/**
 * Analytics - item favorite
 * @memberof constants/AnalyticsConstants
 */
export const ITEM_FAVORITE = 'item_favorite';
/** 
 * Analytics - No, thanks cta
 * @memberof constants/AnalyticsConstants
 */
export const MIAM = 'miam';

/**
 * Analytics - No, thanks cta
 * @memberof constants/AnalyticsConstants
 */
export const COMBO = 'combo';

/**
 * Analytics - contact restaurant
 * @memberof constants/AnalyticsConstants
 */
export const CONTACT_RESTAURANT = 'contact restaurant';

/**
 * Analytics - Get Directions
 * @memberof constants/AnalyticsConstants
 */
export const GET_DIRECTION = 'get directions';

/**
 * Analytics - order_confirmation_click_cta
 * @memberof constants/AnalyticsConstants
 */
export const ORDER_CONFIRMATION_CLICK_CTA = 'order_confirmation_click_cta';
/**
 * Analytics - Driver Tracking CTA
 * @memberof constants/AnalyticsConstants
 * Click action name
 */
export const DRIVER_TRACKING = 'driver tracking';

/**
 * Analytics - Satellite call for doordarsh CTA
 * @memberof constants/AnalyticsConstants
 */
export const VIEW_ORDER_DETAILS = 'view_order_details';

/**
 * Analytics - Yes I'm Here CTA
 * @memberof constants/AnalyticsConstants
 * Click action name
 */
export const YES_IM_HERE = 'yes, i am really here';

/**
 * Analytics - Satellite call for doordarsh CTA
 * @memberof constants/AnalyticsConstants
 */
export const ORDER_CONFIRM_CLICK_CTA = 'order_confirmation_click_cta';

/**
 * Analytics - Satellite call for error
 * @memberof constants/AnalyticsConstants
 */
export const ERROR_MESSAGE_TRACKER = 'errorMessage';

/**
 * Analytics - Hold on not yet CTA
 * @memberof constants/AnalyticsConstants
 * Click action name
 */
export const HOLD_ON = 'hold on, not yet';

/**
 * Analytics - OK CTA
 * @memberof constants/AnalyticsConstants
 * Click action name
 */
export const OK = 'ok';

/**
 * Analytics - Curbside error message
 * @memberof constants/AnalyticsConstants
 */
export const CURBSIDE_ERROR_MSG =
  'Oops! We couldn�t connect with the restaurant right now, please try again, or call the restaurant directly to let them know you�re here.';
/**
 * Analytics - order confirm
 * @memberof constants/AnalyticsConstants
 */
export const ORDER_CONFIRM = 'order confirm';

/**
 * Analytics - pickup
 * @memberof constants/AnalyticsConstants
 */
export const PICKUP = 'pickup';

/**
 * Analytics - In-Store Pickup
 * @memberof constants/AnalyticsConstants
 */
export const IN_STORE_PICKUP = 'In-Store Pickup';

/**
 * Analytics - delivery
 * @memberof constants/AnalyticsConstants
 */
export const DELIVERY = 'delivery';

/**
 * Analytics - Curbside Pickup
 * @memberof constants/AnalyticsConstants
 */
export const CURBSIDE_PICKUP = 'Curbside Pickup';

/**
 * Analytics - no tip
 * @memberof constants/AnalyticsConstants
 */
export const NO_TIP = 'no tip';

/**
 * Analytics - subway
 * @memberof constants/AnalyticsConstants
 */
export const SUBWAY = 'subway';

/**
 * Analytics - orderConfirmationPage
 * @memberof constants/AnalyticsConstants
 */
export const ORDERCONFIRMATION_PAGE = 'orderConfirmationPage';

/**
 * Analytics - giftcard;
 * @memberof constants/AnalyticsConstants
 */
export const GIFTCARD = 'giftcard;';

/**
 * Analytics - for menu page;
 * @memberof constants/AnalyticsConstants
 */
export const MENU = 'menu';

/**
 * Analytics - for account/profile page;
 * @memberof constants/AnalyticsConstants
 */
export const ACCOUNT = 'account';

/**
 * Analytics - for account/profile page;
 * @memberof constants/AnalyticsConstants
 */
export const DASHBOARD_CLICK_ACTION = 'dashboard_click_action';

/**
 * Analytics - for restaurant page;
 * @memberof constants/AnalyticsConstants
 */
export const RESTAURANT = 'restaurant';

/**
 * Analytics - for order-management page;
 * @memberof constants/AnalyticsConstants
 */
export const ORDER_MANAGEMENT = 'order-management';

/**
 * Analytics - for customizer page;
 * @memberof constants/AnalyticsConstants
 */
export const CUSTOMIZER = 'customizer';

/**
 * Analytics - for order-confirmation page;
 * @memberof constants/AnalyticsConstants
 */
export const ORDER_CONFIRMATION = 'order-confirmation';

/**
 * Analytics - for profile page;
 * @memberof constants/AnalyticsConstants
 */
export const PROFILE = 'profile';
/**
 * Analytics - loyalty acquisition modal;
 * @memberof constants/AnalyticsConstants
 */
export const LOYALY_MODAL = 'loyalty acquisition modal';

/**
 * Analytics - myWayRewardsModal;
 * @memberof constants/AnalyticsConstants
 */
export const MYWAYREWARDSMODAL = 'myWayRewardsModal';

/**
 * Analytics - join now;
 * @memberof constants/AnalyticsConstants
 */
export const JOIN_NOW = 'join now';

/**
 * Analytics - sign out;
 * @memberof constants/AnalyticsConstants
 */
 export const SIGN_OUT = 'signout';

 /**
 * Analytics - sign out;
 * @memberof constants/AnalyticsConstants
 */
  export const SIGN_OUT_SPACE = 'sign out';

  /**
 * Analytics - Satellite call for save cta in customer profile page
 * @memberof constants/AnalyticsConstants
 */
export const PROFILE_UPDATE = 'profile_update';

  /**
 * Analytics - Add payments in profile page
 * @memberof constants/AnalyticsConstants
 */
  export const PAYPAL_BANNER_MESSAGE = 'paypal is no longer supported.';

  /**
 * Fallback - recent oder component
 * @memberof constants/AnalyticsConstants
 */
export const RECENT_ORDER = 'recent order';

/**
 * Fallback  - recent order - accordian
 * @memberof constants/AnalyticsConstants
 */
export const ACCORDIAN_COLLAPSE = 'location collapse arrow';


/**
 * Fallback  - recent order - accordian
 * @memberof constants/AnalyticsConstants
 */
export const ACCORDIAN_EXPAND = 'location expand arrow';


/**
 * Fallback  - recent_order_click_action - attempt track
 * @memberof constants/AnalyticsConstants
 */
export const RECENT_ORDER_CLICK_ACTION = 'location expand arrow';

/**
 * Analytics - contactinfo;
 * @memberof constants/AnalyticsConstants
 */
export const CONTACT_INFO = 'contactinfo';

/**
 * Analytics - reset password;
 * @memberof constants/AnalyticsConstants
 */
export const RESET_MY_PASSWORD = 'reset my password';

/**
 * Analytics - delete account?;
 * @memberof constants/AnalyticsConstants
 */
export const DELETE_ACCOUNT = 'delete account?';

/**
 * Footer click action
 * @memberof constants/AnalyticsConstants
 */
export const FOOTER_CLICK_ACTION = 'footer_click_action';

/**
 * Analytics - utensil;
 * @memberof constants/AnalyticsConstants
 */
export const UTENSIL = 'utensil';

/**
 * Analytics - straw;
 * @memberof constants/AnalyticsConstants
 */
export const STRAW = 'straw';

/**
 * Analytics - utensil and straw;
 * @memberof constants/AnalyticsConstants
 */
export const UTENSIL_STRAW = 'utensil|straw';

/**
 * Analytics - deliverylocator;
 * @memberof constants/AnalyticsConstants
 */
export const DELIVERY_LOCATOR = 'deliverylocator';

/**
 * Analytics - predictive store search;
 * @memberof constants/AnalyticsConstants
 */
export const PREDICTIVE_STORE_SEARCH = 'predictive store search';

/**
 * Analytics - location_delivery;
 * @memberof constants/AnalyticsConstants
 */
export const LOCATION_DELIVERY = 'location_delivery';

/**
 * Analytics - deliver to this address;
 * @memberof constants/AnalyticsConstants
 */
export const DELIVER_TO_THIS_ADDRESS = 'deliver to this address';

/**
 * Analytics - header;
 * @memberof constants/AnalyticsConstants
 */
export const HEADER = 'header';

/**
 * Analytics - default store;
 * @memberof constants/AnalyticsConstants
 */
export const DEFAULT_STORE = 'default store';

/**
 * Analytics - subheader;
 * @memberof constants/AnalyticsConstants
 */
export const SUB_HEADER = 'subheader';

/**
 * Analytics - subway logo;
 * @memberof constants/AnalyticsConstants
 */
export const SUBWAY_LOGO = 'subway logo';

/**
 * Analytics - Start Order;
 * @memberof constants/AnalyticsConstants
 */
export const START_ORDER = 'Start Order';

/**
 * Analytics - deal not available error modal;
 * @memberof constants/AnalyticsConstants
 */
export const DEAL_NOT_AVAILABLE_ERROR_MODAL = 'deal not available error modal';

/**
 * Analytics - error warning modal;
 * @memberof constants/AnalyticsConstants
 */
export const ERROR_WARNING_MODAL = 'error warning modal';

/**
 * Analytics - modal_cta;
 * @memberof constants/AnalyticsConstants
 */
export const MODAL_CTA = 'modal_cta';

/**
 * Analytics - predictive store search-recent address;
 * @memberof constants/AnalyticsConstants
 */
export const PREDICTIVE_STORE_SEARCH_RECENT = 'predictive store search-recent address';

/**
 * Analytics - Recent Locations;
 * @memberof constants/AnalyticsConstants
 */
export const RECENT_LOCATIONS = 'Recent Locations';

/**
 * Analytics - Manual Locations;
 * @memberof constants/AnalyticsConstants
 */
export const MANUAL_LOCATIONS = 'Manual Locations';

/**
 * Analytics - Manual Locations;
 * @memberof constants/AnalyticsConstants
 */
export const DEALS_DEATAIL_PAGE_NAME = 'deals details page';


/**
 * Analytics - Legal;
 * @memberof constants/AnalyticsConstants
 */
export const LEGAL = 'legal';

/**
 * Analytics - Loyalty;
 * @memberof constants/AnalyticsConstants
 */
export const LOYALTY = 'loyalty';
/**
 * Analytics - header;
 * @memberof constants/AppConstants
 */
export const FOOTER = 'footer';

/**
 * Analytics - Start Order;
 * @memberof constants/AnalyticsConstants
 */
export const VIEW_BAG = 'View Bag';

/**
 * Analytics - meal click action;
 * @memberof constants/AnalyticsConstants
 */
export const MEAL_CLICK_ACTION = 'meal_click_action';

/**
 * Analytics - kids meal;
 * @memberof constants/AnalyticsConstants
 */
export const KIDS_MEAL = 'kids meal';

/**
 * Analytics - react_confirmationModal_pageView;
 * @memberof constants/AnalyticsConstants
 */
export const CONFIRMATION_MODAL_PAGEVIEW = 'confirmationModal_pageView';

/**
 * Analytics - react_confirmationModal_clickAction;
 * @memberof constants/AnalyticsConstants
 */
export const CONFIRMATION_MODAL_CLICK = 'confirmationModal_clickAction';

/**
 * Analytics - view bag & checkout;
 * @memberof constants/AnalyticsConstants
 */
export const VIEW_BAG_CHECKOUT = 'view bag & checkout';

/**
 * Analytics - continue shopping;
 * @memberof constants/AnalyticsConstants
 */
export const CONTINUE_SHOPPING = 'continue shopping';

/**
 * Analytics - upsell product added;
 * @memberof constants/AnalyticsConstants
 */
export const UPSELL_PRODUCT_ADDED = 'upsell product added';

/**
 * Analytics - confirmation modal;
 * @memberof constants/AnalyticsConstants
 */
export const CONFIRMATION_MODAL = 'confirmation modal';


/**
 * Analytics - one touch product added;
 * @memberof constants/AnalyticsConstants
 */
export const ONETOUCH_PRODUCT_ADDED = 'one touch product added';

/**
 * Analytics - category page;
 * @memberof constants/AnalyticsConstants
 */
export const CATEGORY_PAGE = 'category page';

/**
 * Analytics - promtionalModalCTA;
 * @memberof constants/AnalyticsConstants
 */
export const PROMOTION_MODAL_CTA = 'promtionalModalCTA_clickAction';
/**
 * Analytics - promotional modal;
 * @memberof constants/AnalyticsConstants
 */
export const PROMOTION_MODAL = 'promotional modal';
/**
 * Analytics - payment methods attempt track;
 * @memberof constants/AnalyticsConstants
 */
export const PAYMENTATTEMPTTRACK = 'paymentmethods';
/**
 * Analytics - payment methods;
 * @memberof constants/AnalyticsConstants
 */
export const PAYMENTMETHODS_CTA = 'Add Payment Method';
/**
 * Analytics - Default cta;
 * @memberof constants/AnalyticsConstants
 */
export const DEFAULT_CTA = 'make default';

/**
 * Analytics - one touch product added;
 * @memberof constants/AnalyticsConstants
 */
export const CREATE_YOUR_COMBO = 'create your combo';

/**
 * Analytics - one touch product added;
 * @memberof constants/AnalyticsConstants
 */
export const COMBOLITE_PAGE_VIEW = 'comboLite_pageView';


/**
 * Analytics - confirmation modal upsell;
 * @memberof constants/AnalyticsConstants
 */
export const CONFIRMATION_MODAL_UPSELL = 'confirmation modal upsell';

/**
 * Analytics - name your favorite modal;
 * @memberof constants/AnalyticsConstants
 */
export const NAME_YOUR_FAVORITE_MODAL = 'name your favorite modal';

/**
 * Analytics - favModal;
 * @memberof constants/AnalyticsConstants
 */
export const FAV_MODAL = 'favModal';

/**
 * Analytics - save;
 * @memberof constants/AnalyticsConstants
 */
export const SAVE = 'save';

/**
 * Analytics - react_profile_pageview;
 * @memberof constants/AnalyticsConstants
 */
export const PROFILE_PAGEVIEW = 'profile_pageview';