import { create } from 'zustand';

interface GlobalState {
  isSetFavourite: boolean;
  showFavourite: () => void;
  isSetOnboardingVisible: boolean;
  onboardingVisible: () => void;
  isSetBestSeller: boolean;
  isBestSeller: () => void;
  appConfig: any;
  setAppConfig: (appConfig: any) => void;
  showToastBanner: boolean;
  isShowToastBanner: () => void;
  isHideToastBanner: () => void;
  toastBanner: any;
  setToastBanner: (toastBanner: any) => void;
  removeToastBanner: () => void;
  analyticsProducts: any;
  setAnalyticsProducts: (result: any) => void;
  attemptTracter: any[];
  setAttemptTracker: (values: any[]) => void;
  pageLoadAnalytics: number;
  setPageLoadAnalytics: (value: number) => void;
  noOFComponents: any[];
  setNoOfComponents: (value: any[]) => void;
  totalCards: number;
  setTotalCards: (value: number) => void;
  selectedCard: number;
  setSelectedCard: (value: number) => void;
  cartID: string;
  setCartID: (value: string) => void;
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
  cookieOptions: any[];
  pageDetails: any;
  setPageDetails: (value: string) => void;
  bonusObject: any;
  setBonusObject: (value: any) => void;
  isShowPaypalBanner: boolean;
  showPaypalBanner: (value: boolean) => void;
  cartQuantity: any;
  setCartQuantity: (value: any) => void
  showAnimation: boolean;
  setShowAnimation: (value: boolean) => void
  headerHeightCal: string;
  setHeaderHeight: (value: string) => void
  bannerHeightCal: number;
  setBannerHeightCal: (value: number) => void
  closeDeliveryLocator: boolean;
  setRemovedProductData: (value: any[]) => void;
  removedProductData: any[];
  setCloseDeliveryLocator: (value: boolean) => void;
  isHeaderLoad: boolean;
  setHeaderLoad: (value: boolean) => void;
}

const $SHOW_TOAST_BANNER = 'showToastBanner';

const getInitialToastBanner = () => {
  const value =
    localStorage.getItem($SHOW_TOAST_BANNER) === 'true' ||
    window.orderViewModel.showRedirectSuccessBanner;
  return value || false;
};

const $TOAST_BANNER_MESSAGE = 'toastBannerMessage';

const getInitialToastBannerMessage = () => {
  let details;
  const value = localStorage.getItem($TOAST_BANNER_MESSAGE) === 'true';
  return value ? details : '';
};

const useStore = create<GlobalState>(set => ({
  isSetFavourite: false,
  showFavourite: () => set({ isSetFavourite: true }),
  isSetBestSeller: false,
  isBestSeller: () => set({ isSetBestSeller: true }),
  isSetOnboardingVisible: false,
  onboardingVisible: () => set({ isSetOnboardingVisible: true }),
  appConfig: {},
  setAppConfig: appConfig => set(state => ({ ...state.appConfig, appConfig })),
  toastBanner: getInitialToastBannerMessage(),
  setToastBanner: (toastBanner: any) =>
    set(() => {
      localStorage.setItem($TOAST_BANNER_MESSAGE, true as any);
      return {
        toastBanner: toastBanner,
      };
    }),
  removeToastBanner: () =>
    set(() => {
      localStorage.removeItem($TOAST_BANNER_MESSAGE);
      return {
        toastBanner: undefined,
      };
    }),
  analyticsProducts: {},
  setAnalyticsProducts: (result: any) =>
    set(state => ({ ...state.analyticsProducts, analyticsProducts: result })),
  showToastBanner: getInitialToastBanner(),
  isShowToastBanner: () =>
    set(() => {
      localStorage.setItem($SHOW_TOAST_BANNER, true as any);
      return {
        showToastBanner: true,
      };
    }),
  isHideToastBanner: () =>
    set(() => {
      localStorage.removeItem($SHOW_TOAST_BANNER);
      return {
        showToastBanner: false,
      };
    }),
  attemptTracter: [],
  setAttemptTracker: (value: any[]) =>
    set(state => ({ ...state.attemptTracter, attemptTracter: value })),
  pageLoadAnalytics: 0,
  setPageLoadAnalytics: (value: number) => set({ pageLoadAnalytics: value }),
  noOFComponents: [],
  setNoOfComponents: (value: any) =>
    set(state => ({ ...state.noOFComponents, noOFComponents: value })),
    removedProductData: [],
    setRemovedProductData: (value: any) =>
    set({removedProductData:value}),
  totalCards: 0,
  setTotalCards: (value: number) => set({ totalCards: value }),
  selectedCard: 0,
  setSelectedCard: (value: number) => set({ selectedCard: value }),
  cartID: window.orderViewModel.currentOrder.cartId,
  setCartID: (value: string) => set({ cartID: value }),
  isLoggedIn: false,
  setIsLoggedIn: (value: boolean) => set({ isLoggedIn: value }),
  cookieOptions: [],
  pageDetails: null,
  setPageDetails: (value: string) => set({ pageDetails: value }),
  bonusObject: null,
  setBonusObject: (value: any) => set({ bonusObject: value }),
  isShowPaypalBanner: false,
  showPaypalBanner: (value: boolean) => set({isShowPaypalBanner: value}),
  cartQuantity: window.orderViewModel.currentOrder.cartQuantity,
  showAnimation: false,
  setShowAnimation: (value: boolean) => set({ showAnimation: value }),
  setCartQuantity: (value: any) => set((state) => {
    return {
      cartQuantity: value,
      showAnimation: state.cartQuantity == 0 ? true : false
    }
  }),
  headerHeightCal: '0px',
  setHeaderHeight: (value: string) => set({ headerHeightCal: value }),
  bannerHeightCal: 0,
  setBannerHeightCal: (value: number) => set({ bannerHeightCal: value }),
  closeDeliveryLocator: false,
  setCloseDeliveryLocator: (value: boolean) => set({ closeDeliveryLocator: value }),
  isHeaderLoad: false,
  setHeaderLoad: (value: boolean) => set({ isHeaderLoad: value }),
}));

export default useStore;
