import React from 'react';
import classNames from 'classnames';
import { ButtonProps } from '../../../Interfaces/ButtonProps';
import './Button.scss';

/**
 * Button component
 * @param {String} className class name
 * @param {Boolean} selected flag for selected
 * @param {Boolean} isLoading flag for loading
 * @param {Boolean} isDisabled flag for disable
 * @param {String} icon icon
 * @returns Reusable Button component
 */

const Button: React.FC<ButtonProps> = ({
  className = 'button',
  selected,
  isLoading,
  isDisabled,
  icon,
  testAutoId = 'button',
  ...buttonProps
}) => {
  return (
    <button
      className={classNames(className, {
        'is-loading': isLoading,
        'is-disabled': isDisabled,
        [className + '--outline']: selected, 
      })}
      data-testid={testAutoId}
      data-testauto-id = {`${testAutoId}`}
      {...buttonProps}
    >
      {!!icon && icon}
      <span className="button__content">{buttonProps.children}</span>
    </button>
  );
};

export default Button;