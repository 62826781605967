import React from 'react';
import './Skeleton.scss';

interface SkeletonProps {
  count: number;
  className: string;
  isImage?: boolean;
  isTitle?: boolean;
  isDescription?: boolean;
  isButton?: boolean;
  isButtonText?: boolean;
  titleCount?: number;
}

/**
 * Skeleton component for all components before data is loaded
 * @param param0.className className for skeleton
 * @param param0.isImage display image skeleton if true
 * @param param0.isTitle display title skeleton if true
 * @param param0.isDescription display description skeleton if true
 * @param param0.isButton display button skeleton if true
 * @param param0.isButtonText display button text skeleton if true
 * @returns Reusable Skeleton component for all components
 */
const Skeleton = ({
  count = 1,
  className,
  isImage = true,
  isTitle = true,
  isDescription = true,
  isButton,
  isButtonText,
  titleCount = 1,
}: SkeletonProps) => {
  const SkeletonVariant = `skeleton skeleton--${className}`;
  const skeletonText = [];
  const skeletonNode = [];

  for (let index = 0; index < titleCount; index++) {
    skeletonText.push(
      <div key={index} className="skeleton__text">
        {isTitle && <div className="title"></div>}
        {isDescription && <div className="description"></div>}
      </div>,
    );
  }
  for (let index = 0; index < count; index++) {
    skeletonNode.push(
      <div key={index} className={SkeletonVariant}>
        <div className="skeleton__content">
          {isImage && <div className="image"></div>}
          {(isTitle || isDescription) && skeletonText}
        </div>

        <div className="skeleton__button">
          {isButton && <div className="button"></div>}
          {isButtonText && <div className="button__text"></div>}
        </div>
      </div>,
    );
  }

  return <>{skeletonNode}</>;
};

export default Skeleton;
