/**
 * @module constants/AppConstants
 */

/**
 * Default minimum value for add to cart
 * @memberof constants/AppConstants
 */
export const MIN_VALUE = 1;

/**
 * Default maximum value for add to cart
 * @memberof constants/AppConstants
 */
export const MAX_VALUE = 6;

/**
 * Default number of characters
 * @memberof constants/AppConstants
 */
export const DEFAULT_NUMBER_OF_CHARACTERS = 150;

/**
 * Base URL
 * @memberof constants/AppConstants
 */
export const BASE_URL = window.location.origin;

/**
 * Default Error tilte
 * @memberof constants/AppConstants
 */
export const ERROR_TITLE = 'Oh No';

/**
 * Default Error message
 * @memberof constants/AppConstants
 */

export const ERROR_MESSAGE = 'Some Error Occurs';

/**
 * API urls
 * @memberof constants/AppConstants
 */
export const API_ENDPOINT = {
  INGREDIENTS: '/RemoteOrder/Customizer/GetIngredientsAsync',
  ADD_TO_CART: '/RemoteOrder/Orders/AddItemAsync',
  UPDATE_CART: '/RemoteOrder/Orders/UpdateCartItemAsync',
  COMPOS: '/RemoteOrder/Menu/MakeItAMealAsync',
  COMPOS_SIDES: '/RemoteOrder/Menu/MakeItAMealSidesAsync',
  PRODUCT_SUMMARY: '/RemoteOrder/Orders/productSummary',
  COMPOS_FEATURED: '/RemoteOrder/MenuCombo/GetFeaturedComboOptions',
};

/**
 * Default maximum value for add to cart
 * @memberof constants/AppConstants
 */
export const SIDES_DRINKS_MAX_VALUE = 25;

/**
 * ingredient options extras
 * @memberof constants/AppConstants
 */
export const EXTRAS = 'Extra';

/**
 * ingredient options
 * @memberof constants/AppConstants
 */
export const CHEESE = 'Cheese';

/**
 * ingredient options
 * @memberof constants/AppConstants
 */
export const TOASTED = 'Toasted';
/**
 * ingredient options
 * @memberof constants/AppConstants
 */
export const BREAD = 'Bread';
/**
 * ingredient options
 * @memberof constants/AppConstants
 */
export const PROTEIN = 'Protein';
/**
 * ingredient options
 * @memberof constants/AppConstants
 */
export const VEGGIES = 'Veggies';
/**
 * ingredient options
 * @memberof constants/AppConstants
 */
export const VEGETABLE = 'Vegetable';
/**
 * ingredient options
 * @memberof constants/AppConstants
 */
export const SAUCE = 'Sauce';
/**
 * ingredient options
 * @memberof constants/AppConstants
 */
export const SEASONINGS = 'Seasonings';
/**
 * ingredient options
 * @memberof constants/AppConstants
 */
export const ADD_ONS = 'Add-Ons';

/**
 * ingredient options
 * @memberof constants/AppConstants
 */
export const PASTA = 'Pasta';
/**
 * upsell
 * @memberof constants/AppConstants
 */
export const RICE = 'Rice';
/**
 * Rice
 * @memberof constants/AppConstants
 */
export const UPSELL = 'Upsell';
/**
 * pasta categoryID for pasata
 * @memberof constants/AppConstants
 */
export const PASATA_ID = 878;
/**
 * pasta categoryID for omelete
 * @memberof constants/AppConstants
 */
export const OMELETE_ID = 888;
/**
 * pasta categoryID for ultimate breakfast
 * @memberof constants/AppConstants
 */
export const ULTIMATE_BREAKFAST = 889;
/**
 * Fountain drinks categoryID for pr sitecontext
 * @memberof constants/AppConstants
 */
export const FOUNTAIN_DRINK_ID = 870;
/**
 * Fountain Drink
 * @memberof constants/AppConstants
 */
export const FOUNTAIN_DRINK = 'Fountain Drink';

export const FOUNTAIN_BEVERAGE = 'Fountain Beverage';

export const HOT_BEVERAGE = 'Hot Beverage';
/**
 * Energy K cal
 * @memberof constants/AppConstants
 */
export const ENERGY_K_CAL = 'Energy (kcal)';
/**
 * Energy K cal ID
 * @memberof constants/AppConstants
 */
export const ENERGY_K_CAL_ID = '2';
/**
 * ingredient options
 * @memberof constants/AppConstants
 */
export const DRINKS = 'Drinks';
/**
 * Drinks List
 * @memberof constants/AppConstants
 */
export const DRINKS_LIST = ['Drinks', 'Boissons'];
/**
 * Hot Drinks List
 * @memberof constants/AppConstants
 */
export const HOT_DRINKS_LIST = ['Hot Sides', 'Sides', 'À - côtés '];
/**
 * ingredient options six inch sub
 * @memberof constants/AppConstants
 */
export const SIX_INCH = '6"';
/**
 * ingredient options 12 pro sub
 * @memberof constants/AppConstants
 */
export const TWELVE_PRO = '12 po';
/**
 * drinks medium size
 * @memberof constants/AppConstants
 */
export const MEDIUM_SIZE = 'M';
/**
 * Number 8
 * @memberof constants/AppConstants
 */
export const NUMBER_EIGHT = '8';
/**
 * ingredient options
 * @memberof constants/AppConstants
 */
export const EGG = 'Egg';
/**
 * ingredient options
 * @memberof constants/AppConstants
 */
export const COOKIES = 'Cookies';
/**
 * ingredient options
 * @memberof constants/AppConstants
 */
export const SIDE = 'Side';
/**
 * default placeholder/fallback image
 * @memberof constants/AppConstants
 */
export const DEFAULT_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
/**
 * loading placeholder text
 * @memberof constants/AppConstants
 */
export const LOADING_TEXT = 'Loading component...';
/**
 * Out of stock text
 * @memberof constants/AppConstants
 */
export const OUT_OF_STOCK = 'Out of Stock';
/**
 * global add to cart message
 * @memberof constants/AppConstants
 */
export const ADD_TO_CART_MESSAGE = window.AddToBagSuccessMsg;
/**
 * Out of stock text
 * @memberof constants/AppConstants
 */
export const OK_CTA = 'OK';

/**
 * Clear Cart
 * @memberof constants/AppConstants
 */
export const CLEAR_CART = 'cc';
/**
 * Order Something Else
 * @memberof constants/AppConstants
 */
export const ORDER_SOMETHING_ELSE = 'ose';
/**
 * remove item from cart
 * @memberof constants/AppConstants
 */
export const REMOVE_ITEMS_FROM_CART = 'rifc';
/**
 * remove item from MIAM
 * @memberof constants/AppConstants
 */
export const REMOVE_COMBO_ITEMS = 'removeMIAM';
/**
 * Ordertype - delivery
 * @memberof constants/AppConstants
 */
export const DELIVERY = 'delivery';
/**
 * ActionType: cart page - Edit
 * @memberof constants/AppConstants
 */
export const EDIT = 'edit';
/**
 * ActionType: cart page - Apply
 * @memberof constants/AppConstants
 */
export const APPLY = 'apply';
/**
 * ActionType: cart page - Remove
 * @memberof constants/AppConstants
 */
export const REMOVE = 'remove';

export const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

/**
 * ActionType: cart page - Quick-Add-Ons
 * @memberof constants/AppConstants
 */
export const QUICK_ADD_ONS = 'quick-add ons';

export const REGULAR_QUICK_ADD_ONS = 'regular quick-add ons';

export const QUICK_ADD_ON = 'quick add on';

export const QUICK_ADD_ON_FR = "ajouts rapides";

export const QUICK_ADD_ON_ES = "adiciones rápidas";
/**
 * ActionType: cart page - Cart
 * @memberof constants/AppConstants
 */
export const CART = 'cart';
export const ERRORCODE_3007 = 'DORD-3007';

export const CULTURE_CODE_CA = 'CA';
export const CULTURE_CODE_PR = 'PR';

export const ORDER_TYPE_CURBSIDE = 'curbside';
export const valuesToCheck = ['Ultimate Breakfast', 'Kids'];
/**
 * load next step count
 * @memberof constants/AppConstants
 */
export const LOAD_NEXT_COUNT = 5;
/**
 * class name to identify the view rewards or start order CTA
 * @memberof constants/AppConstants
 */
export const START_ORDER = 'start_order';
/**
 * back button
 * @memberof constants/AppConstants
 */
export const BACK = 'back';
/**
 * close icon
 * @memberof constants/AppConstants
 */
export const CLOSE = 'close';
/**
 * New Reward Notification ID in Dashboard.
 * @memberof constants/AppConstants
 */
export const NEW_REWARD_NOT_ID = '101';
/**
 * CustomSlider Previous Button Text.
 * @memberof constants/AppConstants
 */
export const PreviousButtonText = 'Previous';
/**
 * CustomSlider Next Button Text.
 * @memberof constants/AppConstants
 */
export const NextButtonText = 'Next';
/**
 * Deal type.
 * @memberof constants/AppConstants
 */
export const PreviousButtonTextDisabled = 'Previous button unavailable';
/**
 * CustomSlider Next Button Text.
 * @memberof constants/AppConstants
 */
export const NextButtonTextDisabled = 'Next button unavailable';
/**
 * Deal type.
 * @memberof constants/AppConstants
 */
export const DEAL_NATIONAL = 'National';
export const BONUS = 'Bonus';
export const NON_BONUS = 'Non Bonus';
export const COMBO_LITE = 'combo-lite';
/**
 * Deal type.
 * @memberof constants/AppConstants
 */
export const DEAL_NON_SD = 'Non S&D';
/**
 * Deal type.
 * @memberof constants/AppConstants
 */
export const DEAL_SD = 'S&D';
/**
 * Deal type.
 * @memberof constants/AppConstants
 */
export const DEAL_SUBWAYMERCH = 'SubwayMerch';

/**
 * UpdatePhone popup.
 * This mathcer will break the user input into (xxx) xxx-xxxx format.
 */
export const DIGIT_PARTITION_ARRAY = /(\d{0,3})(\d{0,3})(\d{0,4})/;

/**
 * Number pattern
 */
export const DIGIT_PATTERN = /\d+/g;
/**
 * Deals page
 * To fetch the Expires label from sitecore dictionary provided in render JSON.
 */
export const EXPIRES = 'Expires';
/**
 * Deals page
 * To fetch the disclaimer label from sitecore dictionary provided in render JSON.
 */
export const REWARD_NOTE = 'RewardsNote';

/**
 * Deal Modal Click Actions
 * @memberof constants/AppConstants
 */
export const FINDNEWLOCATION = 'FINDNEWLOCATION';
export const CONTINUEORDER = 'CONTINUEORDER';
export const SWITCHTOPICKUP = 'SWITCHTOPICKUP';
export const KEEPDELIVERY = 'KEEPDELIVERY';

/**
 * Analytics add to bag source
 * @memberof constants/AppConstants
 */
export const CART_QUICKADDONS_SOURCE = 'cart quick-add ons';

/**
 * Analytics add to bag source
 * @memberof constants/AppConstants
 */
export const CART_INCREMENT_SOURCE = 'cart quantity increase';
/**
 * PR culture language code
 * @memberof constants/AppConstants
 */
export const PR = 'PR';
/**
 * RESET
 * @memberof constants/AppConstants
 */
export const RESET = 'RESET';

/**
 * top
 * @memberof constants/AppConstants
 */
export const TOP = 'top';

/**
 * bottom
 * @memberof constants/AppConstants
 */
export const BOTTOM = 'bottom';

/**
 * Biscuits
 * @memberof constants/AppConstants
 */
export const BISCUTS = 'Biscuits';

/**
 * Analytics
 * @memberof constants/AppConstants
 */
export const BEST_SELLER = 'best seller';

/**
 * Analytics
 * @memberof constants/AppConstants
 */
export const PDP_LABEL = 'pdp';

/**
 * Analytics
 * @memberof constants/AppConstants
 */
export const PDP_UPSELL_CAROUSEL = 'pdp upsell carousel';

/**
 * Analytics
 * @memberof constants/AppConstants
 */
export const NO_ID = 'NO_ID';

/**
 * minus
 * @memberof constants/AppConstants
 */
export const MINUS = 'minus';

/**
 * plus
 * @memberof constants/AppConstants
 */
export const PLUS = 'plus';
/**
 * PR culture language code
 * @memberof constants/AppConstants
 * Order status for order confirmation tracker
 */
export const VOIDED = 'voided';

/**
 * Order confirmation
 * @memberof constants/AppConstants
 * Tracker status style
 */
export const NOTSTARTED = 'notstarted';

/**
 * Order confirmation
 * @memberof constants/AppConstants
 * Tracker status style
 */
export const NOTYETSTARTED = 'not yet started';

/**
 * Order confirmation
 * @memberof constants/AppConstants
 * Tracker status style
 */
export const INPROGRESS = 'inprogress';

/**
 * Order confirmation
 * @memberof constants/AppConstants
 * Tracker status style
 */
export const COMPLETED = 'completed';

/**
 * Order confirmation
 * @memberof constants/AppConstants
 * Tracker status identifier
 */
export const RECEIVED = 'received';

/**
 * Order confirmation
 * @memberof constants/AppConstants
 * Tracker status style
 */
export const BUILDING = 'building';

/**
 * Order confirmation
 * @memberof constants/AppConstants
 * Tracker status style
 */
export const READY = 'ready';

/**
 * Order confirmation
 * @memberof constants/AppConstants
 * Tracker status style
 */
export const PREPARING = 'preparing';

/**
 * Order confirmation
 * @memberof constants/AppConstants
 * Tracker status style
 */
export const ONTHEWAY = 'onTheWay';

/**
 * Order confirmation
 * @memberof constants/AppConstants
 * Tracker status style
 */
export const DELIVERED = 'delivered';

/**
 * Default maximum value of the characters
 * @memberof constants/AppConstants
 */
export const MAX_LENGTH = 60;

/**
 * Default value for order confirmation
 * @memberof constants/AppConstants
 */
export const ORDERCONFIRMATION = 'orderconfirmation';

/**
 * Order confirmation
 * @memberof constants/AppConstants
 * href attribute value for phone numbers
 */
export const TEL = 'tel:';

/**
 * Profile page
 * @memberof constants/AppConstants
 * Logout
 */
export const LOGOUT = 'auth/logout';
/**
 * Profile page
 * @memberof constants/AppConstants
 * paymentMent Method types
 */
export const paymentMethods = {
  VISA: 'Visa',
  SUBWAY_CARD: 'SubwayCard',
  MASTER_CARD: 'Mastercard',
  CUP: 'cup',
};

/**
 * Remove favorite
 * @memberof constants/AppConstants
 */
export const REMOVE_FAVORITE = 'Remove Favorite';

/**
 * Recent Order
 * @memberof constants/AppConstants
 */
export const RECENT_ORDER = 'Recent Order';

/**
 * Recent Order
 * @memberof constants/AppConstants
 */
export const REORDER = 'reorder';


/**
 * Recent Order
 * @memberof constants/AppConstants
 */
export const FAVORITES = 'favorites';
export const FAVORITE = 'favorite';

/**
 * pickup
 * @memberof constants/AppConstants
 */
export const PICKUP = 'pickup';
/**
 * Customer profile page
 * @memberof constants/AppConstants
 * zip code regex patterns
 * */
export const zipCodeByCountryPattern = {
  US: '^[0-9]{5}(?:-[0-9]{4})?$',
  CA: '^[A-Za-z][0-9][A-Za-z][ ]?[0-9][A-Za-z][0-9]$',
  VI: '^[0-9]{5}(?:-[0-9]{4})?$',
  PR: '^[0-9]{5}(?:-[0-9]{4})?$',
  MP: '^[0-9]{5}(?:-[0-9]{4})?$',
  GU: '^[0-9]{5}(?:-[0-9]{4})?$',
  AS: '^[0-9]{5}(?:-[0-9]{4})?$',
  CH: '^[0-9]{5}(?:-[0-9]{4})?$',
};

/**
 * Customer Profile page.
 * This mathcer will check the first name/last name is valid.
 */
export const NAMES_REGEX = /^([a-zA-Z])+([a-zA-Z\-\ ]?)+([a-zA-Z]*$)/;

/**
 * Customer Profile page.
 * Field names for identifying inside reusable components
 */
export const customerProfileField = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phone: 'phoneNumber',
  zip: 'postalCode',
};

/**
 * Customer Profile page.
 * For identifying the language preference
 */
export const LANGUAGE = 'Language';

/**
 * Progress Bar
 * @memberof constants/AppConstants
 */
export const REWARDS_PROGRESS_BAR_BGCOLOR = ['darkGreen', 'veryDarkGreen', 'dark'];
export const PERCENTAGE_VALUE = 100;

/**
 * Utensils
 * @memberof constants/AppConstants
 */
export const UTENSILS = 'utensils';

/**
 * country
 * @memberof constants/AppConstants
 */
export const COUNTRY = 'country';

/**
 * can
 * @memberof constants/AppConstants
 */
export const CAN = 'can';
/**
 * USA
 * @memberof constants/AppConstants
 */
export const USA = 'usa';
/**
 * Footer identifier
 * @memberof constants/AppConstants
 */
export const FOOTER = 'Footer';
/**
 * Footer identifier
 * @memberof constants/AppConstants
 */
export const HORIZONTAL_FOOTER = 'HorizontalFooter';
/**
 * Footer identifier
 * @memberof constants/AppConstants
 */
export const SOCIAL_MEDIA = 'SocialMedia';
/**
 * Footer identifier
 * @memberof constants/AppConstants
 */
export const LEGAL_COPY = 'LegalCopy';
/**
 * default map address
 * @memberof constants/AppConstants
 */
export const DEFAULT_MAP_ADDRESS =
  '3888 Newtown Creek, Queens, NY 11101 Sunnyside, New York Queens, New York, Astoria New York United States';

/**
 * Footer identifier
 * @memberof constants/AppConstants
 */
export const COOKIE_SETTINGS = 'cookie settings';

export const excludedPagesAnalytics = ["rewards-activity","my-deals"]

/**
 * Fallback  regular
 * @memberof constants/AppConstants
 */
export const REGULAR = 'Regular';

/**
 *  APPLE_PAY payment Type
 * @memberof constants/AppConstants
 */
export const APPLE_PAY = 'ApplePay';


/**
 *  GOOGLE_PAY payment Type
 * @memberof constants/AppConstants
 */
export const GOOGLE_PAY = 'GooglePay';

/**
 *  WEB_PAY payment Type
 * @memberof constants/AppConstants
 */
export const WEB = 'Web';

/**
 *  Commbo lite restriction for specific cultures
 * @memberof constants/AppConstants
 */
export const cultureLanguage = ["US", "CA"];