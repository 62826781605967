import React, { useEffect, useState, useRef } from 'react';
import Heading from '../../../Atoms/Heading/Heading';
import PromoOffer from '../../../../assets/RemoteOrder/icons/PromoOffer.svg';
import SurprisesOffer from '../../../../assets/RemoteOrder/icons/Surprises.svg';
import SvgIconClose from '../../../../assets/RemoteOrder/icons/CloseWithoutCircle.svg';
import './OrderSummary.scss';
import UseWindowWidth from '../../../../hooks/useWindowWidth';
import Button from '../../../Atoms/Button/Button';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import __pick from 'lodash/pick';
import { currencyPlacement } from '../../../../Utils/pageUtils';
import DeliveryFeeDescription from '../../../Molecules/DeliveryFeeDescription/DeliveryFeeDescription';
import useStore from '../../../../store/useStore';
import { CLOSE, specialChars } from '../../../../constants/App';
import { LOGINCONTINUE, JOIN_SIGN_IN } from '../../../../constants/AnalyticsConstants';
import OrderPaymentBreakups from '../../../Molecules/OrderPaymentBreakups/OrderPaymentBreakups';
import { componentRenderCheck, multipleAndCheck, multipleOrCheck, ternaryCheck } from '../../../../Utils/WindowUtils';

interface OrderSummaryProps {
  summaryData: any;
  summaryLabels: any;
  dictionaryLables: any;
  orderTypeDetails: any;
  orderViewModel: any;
  deliverySelection: boolean;
  onPromoClose: (list: any) => void;
  applyPromoCode: (promoValue: string) => void;
  promoResponse: any;
  notOfferedData: any;
  refetchOrderManagement: (isCurbsidePopup?: boolean, continueCta?: string) => void;
  analyticsProduct?: any;
}

const OrderSummary: React.FunctionComponent<OrderSummaryProps> = ({
  summaryData,
  summaryLabels,
  dictionaryLables,
  orderTypeDetails,
  orderViewModel,
  deliverySelection,
  applyPromoCode,
  promoResponse,
  notOfferedData,
  onPromoClose,
  refetchOrderManagement,
  analyticsProduct,
}) => {
  const { orderSummaryData } = summaryData;
  const loggedOutData = __pick(orderTypeDetails.loggedOutData, [
    'continue',
    'continueGuest',
    'loginContinue',
    'rewardsText',
    'signUpLink',
    'subwayMyWayRewardsLogo',
  ]);
  const loggedData = __pick(orderTypeDetails.loggedData, [
    'continue',
   
   'rewardsIcon',
    'payUrl',
  ]);
  const [promoValue, setPromoValue] = useState('');
  const [isInvalidPromo, setIsInvalidPromo] = useState<boolean>(false);
  const [isSpecialCharacter, setIsSpecialCharacter] = useState<boolean>(false);
  const [invalidvalue, setInvalidvalue] = useState<string>('');
  const [noOfferedMessage, setNoOfferedMessage] = useState<string>('');
  const [noOfferedLocation, setNoOfferedLocation] = useState<any>({});
  const [specialCharacter, setSpecialCharacter] = useState<string>('');
  const offers = _get(summaryData, ['offers'], []);
  const isPromoOffer = multipleAndCheck(!_isEmpty(offers) , offers.some((offer: any) => offer.isPromoOfferID));
  const isLoggedIn = useStore((state: any) => state.isLoggedIn);
  const { setAnalyticsProducts, setAttemptTracker } = useStore();
  const [vanityCodeFlag, setVanityCodeFlag] = useState();
  const [isLoyaltyFlag, setIsLoyaltyFlag] = useState();

  useEffect(() => {
    const flagValue = (window as any).client?.variation(
      (window as any).FF_VANITY_CODE_ENABLED,
      true
    );
    const LoyaltyLDFlag = window.client?.variation('FF-ENABLE-LOYALTY', false);
    if(LoyaltyLDFlag){
      (window as any).getBannerMessage(
      'error',
      (window as any).LoyaltyRewardsUnavailableMsg
    );
  }
    setIsLoyaltyFlag(LoyaltyLDFlag);
    setVanityCodeFlag(flagValue);
  }, [window.client?.variation('FF-ENABLE-LOYALTY', false), (window as any).client?.variation((window as any).FF_VANITY_CODE_ENABLED)]);
  

  const promoInput = (e: any) => {
    setPromoValue(e.target.value);
    if (specialChars.test(e.target.value)) {
      setIsSpecialCharacter(true);
      setSpecialCharacter(summaryLabels.promoSpecialCaracters);
    }
    if (!specialChars.test(e.target.value)) {
      setIsSpecialCharacter(false);
      setSpecialCharacter('');
    }
    if (!e.target.value) {
      setIsInvalidPromo(false);
      setIsSpecialCharacter(false);
      setInvalidvalue('');
      setNoOfferedMessage('');
      setNoOfferedLocation({});
      setSpecialCharacter('');
    }
  };
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!_isEmpty(promoResponse) || !_isEmpty(notOfferedData)) {
      setIsInvalidPromo(promoResponse.invalid || !promoResponse.applied);
      setInvalidvalue(promoResponse.message);
      setNoOfferedMessage(notOfferedData.message);
      setNoOfferedLocation({
        locationtext: notOfferedData.ctaTextForParticipatingLocation,
        locationURL: notOfferedData.promoCodeUrlForLocator,
      });
      setIsSpecialCharacter(false);
    }
    if (_isEmpty(notOfferedData)) {
      setNoOfferedMessage('');
      setNoOfferedLocation({});
    }
    if (promoResponse.invalid || !promoResponse.applied) {
      inputRef.current?.focus();
    }
  }, [promoResponse, notOfferedData]);

  const loginToContinue = () => {
    setAnalyticsProducts({
      clickActionName: JOIN_SIGN_IN,
    });
    setAttemptTracker([{ name: LOGINCONTINUE }]);
    setTimeout(() => {
      window.location.href = loggedOutData.signUpLink;
    }, 100);
  };
  const pointsText = !_isEmpty(orderSummaryData.rewardsSubText) && orderSummaryData.rewardsSubText.split('*');
  

  const getDeliverySelection = () => {
    return deliverySelection ? loggedOutData.continue : loggedOutData.continueGuest;
  }

  return (
    <div className="order-summary">
      {componentRenderCheck(UseWindowWidth() > 1024 , (
        <Heading level="h2" className="order-summary--title" testAutoId='order-summary-title' title={summaryLabels.orderSummary} />
      ))}
      {componentRenderCheck(multipleAndCheck(!isPromoOffer , vanityCodeFlag) , (
        <div className="order-summary__promoCode">
          <label htmlFor="promo-input" className="promo">
            Enter Promo Code:
          </label>
          <input
            id="promo-input"
            type="text"
            value={promoValue}
            data-testauto-id={'promo-input-text'}
            className={classNames('order-summary__promoText', {
              'invalid-promo':multipleOrCheck(isInvalidPromo , isSpecialCharacter),
            })}
            onChange={e => promoInput(e)}
            maxLength={20}
            placeholder={summaryLabels.promoCodeWatermark}
            aria-label={isInvalidPromo || isSpecialCharacter ? invalidvalue || noOfferedMessage || specialCharacter : ''}
            ref={inputRef}
          />
          <Button
            testAutoId={'apply-promocode-btn'}
            onClick={() => applyPromoCode(promoValue)}
            className={classNames(
              'secondary__btn secondary__btn--upper secondary__btn--white secondary__btn--darkHover secondary__btn--promoBtn',
              {
                'is-disabled': multipleOrCheck(!promoValue , isSpecialCharacter),
              },
            )}
            aria-label={`${ternaryCheck(!promoValue , 'Disabled ' + summaryLabels.apply , summaryLabels.apply)}`}
          >
            {summaryLabels.apply}
          </Button>
        </div>
      ))}
      <div className="order-summary__promoError">
        {componentRenderCheck(
          multipleOrCheck(
            multipleAndCheck(isInvalidPromo, invalidvalue, _isEmpty(offers)),
            multipleAndCheck(noOfferedMessage, _isEmpty(offers)),
            multipleAndCheck(specialCharacter, _isEmpty(offers))
          ),
          (
            <p className="order-summary__promoErrorValue" data-testauto-id='cart-promo-error-msg'>
              {invalidvalue ?? noOfferedMessage ?? specialCharacter}
            </p>
          ))}
        {componentRenderCheck(!_isEmpty(noOfferedLocation) , (
          <a
            data-testauto-id={'promoErrorLocation'}
            className="order-summary__promoErrorLocation"
            href={`${window.subwayScript.navigation.getLocatorUrl()}/${noOfferedLocation.locationURL
              }`}
          >
            {noOfferedLocation.locationtext}
          </a>
        ))}
        {!_isEmpty(offers) &&
          offers.map((list: any,index:number) => {
            return (
              <>
                <div key={list.offerId} className="order-summary__promoDetails">
                  <div className="order-summary__svg">
                    {ternaryCheck(list.isReward , <SurprisesOffer /> , <PromoOffer />)}
                  </div>
                  <div className="order-summary__promoContent">
                    {<h4 className="order-summary__promoTitle" data-testauto-id={`cart-promo-code-${index}`}>{list.promoCode}</h4>}
                    {componentRenderCheck(list?.offerDescription , (
                      <p className="order-summary__promoDesc" data-testauto-id={`cart-promo-descrip-${index}`}>{list.offerDescription}</p>
                    ))}
                  </div>
                  <Button
                    testAutoId={'order-promoClose'}
                    className="order-summary__promoClose primary__btn--onlyIcon"
                    onClick={() => {
                      onPromoClose(list);
                      setPromoValue('');
                      setIsInvalidPromo(false);
                      setIsSpecialCharacter(false);
                      setInvalidvalue('');
                      setNoOfferedMessage('');
                      setNoOfferedLocation({});
                      setSpecialCharacter('');
                    }}
                    icon={<SvgIconClose />}
                    title={CLOSE}
                  ></Button>
                </div>
                {componentRenderCheck(!list.applied , (
                  <p className="order-summary__promoErrorValue" data-testauto-id={`cart-promo-apply-error-${index}`}>
                    {summaryLabels.promoQualifiedItems}
                  </p>
                ))}
              </>
            );
          })}
      </div>
      {componentRenderCheck(vanityCodeFlag ?? false,
        <div className="order-summary__offerDisclaimer">
          <p data-testauto-id='cart-vanity-code-disclaim'>{summaryLabels.offersDisclaimer}</p>
        </div>
      )}
      <OrderPaymentBreakups
        summaryData={{ ...summaryData, ...orderTypeDetails }}
        dictionaryLables={{ ...dictionaryLables, ...summaryLabels }}
      />
      {componentRenderCheck(orderSummaryData?.deliveryFeeDescription , (
        <div className="order-summary_deliveryFeeDescription">
          <DeliveryFeeDescription
            deliveryFeeDescription={orderSummaryData.deliveryFeeDescription}
            howFeeWork={orderSummaryData.howFeeWork}
            feeWorkOverlay={orderSummaryData.feeWorkOverlay}
            orderViewModel={orderViewModel}
            informationIcon={orderSummaryData.informationIcon}
          />
        </div>
      ))}
       {
        componentRenderCheck(multipleAndCheck(!_isEmpty(loggedData) , !_isEmpty(pointsText) , !isLoyaltyFlag) ,
        (<div className="order-summary__rewardPoint">
            <img src={loggedData.rewardsIcon} alt="" className="reward_logo" data-testauto-id='order-logged-in-rewards-icon' />
            <div className='order-summary__subreward_group'>
              <Heading level="p" title={pointsText[0]} testAutoId='order-sub-reward-0' className="subreward__text" />
              <Heading level="p" title={pointsText[1]} testAutoId='order-sub-reward-1'  className="subreward__text bold" />
              <Heading level="p" title={pointsText[2]} testAutoId='order-sub-reward-2'  className="subreward__text" />
            </div>
          </div>)
       )
      }
      {ternaryCheck(isLoggedIn , (
        <div className="order-summary__checkout">
          <div className="order-summary__checkout__wrapper">
            <Button
              role='link'
              testAutoId={'order-checkout'}
              className="cart__button greenButton"
              onClick={() => {
                 refetchOrderManagement(false);
              }}
            >
              {loggedData.continue + " " +
                currencyPlacement(dictionaryLables.currency, summaryData.youPay)}
            </Button>
          </div>
        </div>
      ) , (
        <div className="order-summary__earn-rewards">
          <div className="order-summary__reward">
            <img src={loggedOutData.subwayMyWayRewardsLogo} alt="" className="subwayMyWayRewards_logo" data-testauto-id='order-guest-rewards-icon'/>
            <div className="order-summary__rewardtext">
              <Heading level="h2" title={loggedOutData.rewardsText} testAutoId='order-sub-reward-guest-title' className="reward__text" />
              <Heading level="span" title={pointsText[0]} testAutoId='order-sub-reward-guest-0' className="subreward__text" />
              <Heading level="span" title={pointsText[1]} testAutoId='order-sub-reward-guest-1' className="subreward__text bold" />
              <Heading level="span" title={pointsText[2]} testAutoId='order-sub-reward-guest-2' className="subreward__text" />
            </div>
          </div>

          <div className="order-summary__checkout">
            <div className="order-summary__checkout__wrapper">
              <Button
                testAutoId={'loginContinue'}
                className="cart__button greenButton"
                onClick={() => loginToContinue()}
              >
                {loggedOutData.loginContinue}
              </Button>
              <Button
                testAutoId={'loggedOutDdata-btn'}
                className="cart__button"
                onClick={() => {refetchOrderManagement(false)}}
              >
                {getDeliverySelection()}
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderSummary;
