import React from 'react';
import { commaSeparated } from '../../../../Utils/pageUtils';
import Button from '../../../Atoms/Button/Button';
import Modal from '../../../Molecules/Modal/Modal';
import { Items } from '../../../Interfaces/OrderManagementInterface';
import './ErrorPopup.scss';
import {
  CLEAR_CART,
  ORDER_SOMETHING_ELSE,
  REMOVE_COMBO_ITEMS,
  REMOVE_ITEMS_FROM_CART,
} from '../../../../constants/App';
import Heading from '../../../Atoms/Heading/Heading';

interface ErrorPopupProps {
  error: any;
  errorDictionary: any;
  items: Items[];
  flags: any;
  closeModal: () => void;
  unavailableComoboWarning: any;
  outOfStockModal: boolean;
  clearCart: boolean;
  removeItemsFromCart: (removalType: string) => void;
  itemsData: any;
  isRemoveItem: boolean;
  handleRemoveSides: (cta: string) => void;
  handleOkClick: (outOfStock: string) => void;
}
const ErrorPopup: React.FunctionComponent<ErrorPopupProps> = ({
  error,
  errorDictionary,
  items,
  flags,
  closeModal,
  unavailableComoboWarning,
  outOfStockModal,
  clearCart,
  removeItemsFromCart,
  itemsData,
  isRemoveItem,
  handleRemoveSides,
  handleOkClick,
}) => {
  const title = () => {
    if (error) {
      return error.title;
    }
    if (outOfStockModal) {
      return errorDictionary.wereSorry;
    }
    if (isRemoveItem) {
      return itemsData.removeTitle;
    }
    if (clearCart) {
      return errorDictionary.clearCartTitle;
    }
  };

  const description = () => {
    if (error) {
      return error.message;
    }
    if (outOfStockModal) {
      return errorDictionary.unavailableProductsSubcopyA;
    }
    if (isRemoveItem) {
      return itemsData.removeDescription;
    }
    if (clearCart) {
      return '';
    }
  };

  return (
    <Modal
      data-testauto-id = {'errorPopup'}
      isShown={true}
      isClosebtn={true}
      title={title()}
      description={description()}
      onButtonClick={() => closeModal()}
    >
      {items && !clearCart && !isRemoveItem && (
        <>
          <div className="product__avaliability">
            {(flags.hasAllOutStockProducts || flags.hasSomeOutStockProducts) &&
              !flags.isIngredientWarning &&
              flags.outStockProducts.length > 0 &&
              flags.outStockProducts.map((item: any, index: number) => (
                <div key={index} className="product__name">
                  <Heading className="product__name" level="h4" title={item.outStockProduct} />
                  <div className="product__ingredients">{itemsData.productUnavailable}</div>
                </div>
              ))}

            {flags.isIngredientWarning &&
              items
                ?.filter(item => item.noIngredients?.length > 0)
                .map(item => (
                  <div key={item.cartItemId} className="product__name">
                    <Heading className="product__name" level="h4" title={item.name} />
                    <div className="product__ingredients">
                      {itemsData.unavailableItems + commaSeparated(item.noIngredients)}
                    </div>
                  </div>
                ))}

            {flags.hasAllOutStockcombo &&
              !flags.isIngredientWarning &&
              unavailableComoboWarning &&
              unavailableComoboWarning.length > 0 &&
              unavailableComoboWarning.map((item: any) => (
                <div key={item.id} className="product__name">
                  -{item.name}
                </div>
              ))}
          </div>

          {flags.isIngredientWarning && (
            <Button
              className="popup-btn--ok btn w-100"
              testAutoId={'popup-btn-ok'}
              onClick={() => handleOkClick(errorDictionary.outofStock)}
            >
              {errorDictionary.ok}
            </Button>
          )}

          {(flags.hasSomeOutStockProducts || flags.hasAllOutStockcombo) &&
            !flags.isIngredientWarning && (
              <Button
                testAutoId={'removeItemFromCart-btn'}
                className="popup-btn--remove btn w-100"
                onClick={() =>
                  removeItemsFromCart(
                    flags.hasAllOutStockcombo ? REMOVE_COMBO_ITEMS : REMOVE_ITEMS_FROM_CART,
                  )
                }
              >
                {errorDictionary.removeItemFromCart}
              </Button>
            )}
          {flags.hasAllOutStockProducts &&
            !flags.hasSomeOutStockProducts &&
            !flags.isIngredientWarning && (
              <Button
                testAutoId={'orderSomethingElse'}
                className="popup-btn--remove btn w-100"
                onClick={() => removeItemsFromCart(ORDER_SOMETHING_ELSE)}
              >
                {errorDictionary.orderSomethingElse}
              </Button>
            )}
        </>
      )}
      {clearCart && !isRemoveItem && (
        <>
          <Button testAutoId={'cancel-btn-id'} className="popup-btn--cancel btn w-100 button" onClick={() => closeModal()}>
            {errorDictionary.cancel}
          </Button>
          <Button
            testAutoId={'clearMyCart-btn'}
            className="secondary__btn secondary__btn--white secondary__btn--hover secondary__btn--upper secondary__btn--full-width button"
            onClick={() => removeItemsFromCart(CLEAR_CART)}
          >
            {errorDictionary.clearMyCart}
          </Button>
        </>
      )}
      {isRemoveItem && !clearCart && (
        <div className="cta-buttons">
          <Button
            onClick={() => handleRemoveSides(itemsData.removeItemCTA)}
            testAutoId={'remove-item-cta'}
            className="popup-btn--ok btn w-100"
          >
            {itemsData.removeItemCTA}
          </Button>

          <Button testAutoId={'keep-item-cta'} className="popup-btn--remove btn btn-border w-100" onClick={() => closeModal()}>
            {itemsData.keepItemCTA}
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default ErrorPopup;
