import React from 'react';
import Button from '../../../Atoms/Button/Button';
import Heading from '../../../Atoms/Heading/Heading';
import CardMenu from '../../../Molecules/Card/CardList';
import SvgIconMinus from '../../../../assets/RemoteOrder/icons/Minus-black.svg';
import SvgIconPlus from '../../../../assets/RemoteOrder/icons/Plus-black.svg';
import Giftbox from '../../../../assets/RemoteOrder/img/Giftbox.png';
import MiamTagYellow from '../../../../assets/RemoteOrder/img/MiamTagYellow.png';
import MiamTagWhite from '../../../../assets/RemoteOrder/img/MiamTagWhite.png';
import SvgIconclose from '../../../../assets/RemoteOrder/icons/Close.svg';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  commaSeparated,
  currencyPlacement,
  reactEngageApptentiveEvent,
} from '../../../../Utils/pageUtils';
import './CartItems.scss';
import { Components } from '../../../Interfaces/OrderManagementInterface';
import useStore from '../../../../store/useStore';
import { CART_CTA, KEEP_SHOPING, PRODUCT_EDIT } from '../../../../constants/AnalyticsConstants';
import {
  cartComboName,
  getCartItemName,
  getItemClickedEvent,
} from '../../../../Utils/componentUtils';
import { EDIT, cultureLanguage } from '../../../../constants/App';
import { getSides, ternaryCheck,componentRenderCheck,multipleAndCheck } from '../../../../Utils/WindowUtils';

interface CartItemsProps {
  cartItems: any;
  itemsData: any;
  orderTypeDetails: any;
  orderViewModel: any;
  cartId: string;
  dictionaryLables: any;
  adaDictionaryData: any;
  setItemToRemoveHandler: (
    value: string,
    isMeal: boolean,
    quantity: number,
    isRemove: boolean,
    offerId: string,
    isBundleItem: boolean,
    bundleId: string,
  ) => void;
  copyCartItem: (
    cartId: string,
    cartItemId: string,
    categoryId: string,
    masterProductId: number,
    productId: number,
    isBundleItem: boolean,
    bundleId: string,
  ) => void;
  handleApplyOrEdit: (
    link: string,
    productId: number,
    actionType: string,
    cartItemId: string,
    offerId: string,
    isKidsMeal: boolean,
    isBundleItem: boolean,
    bundleId: string,
  ) => void;
  itemRemove: (
    cartItemId: string,
    quantity: number,
    isRewardRemove: boolean,
    isBundleItem: boolean,
    bundleId: string,
  ) => void;
}
const CartItems: React.FC<CartItemsProps> = ({
  orderViewModel,
  cartItems,
  itemsData,
  orderTypeDetails,
  cartId,
  dictionaryLables,
  adaDictionaryData,
  setItemToRemoveHandler,
  copyCartItem,
  handleApplyOrEdit,
  itemRemove,
}) => {
  const { setAttemptTracker, setAnalyticsProducts } = useStore();
  const { isBAUPdp } = useStore(state => state.appConfig);
  const keepShopping = () => {
    setAnalyticsProducts({
      startonlineorder: 0,
      clickActionName: KEEP_SHOPING,
    });
    setAttemptTracker([{ name: CART_CTA }]);
    setTimeout(() => {
      const href = `/${orderViewModel.headerFooterLanguageCode}/restaurant/${orderTypeDetails.storeData.storeId}/menu`;
      reactEngageApptentiveEvent('cart keep shopping', true, href);
    }, 100);
  };
  const handleProductEdit = (e: any,subCategory: string) => {
    setAnalyticsProducts({
      clickActionName: PRODUCT_EDIT,
    });
    setAttemptTracker([{ name: CART_CTA }]);

    e.stopPropagation();
    window.orderViewModel.comboEditClick = false;
    window.orderViewModel.subCategory = subCategory;
    window.orderViewModel.save();
  };

  const getCardIcon = (isMeal: boolean, isRewards: boolean) => {
    if (isRewards) return Giftbox;
    if (isMeal) return MiamTagYellow;
    return MiamTagWhite;
  };
  const getCardButtonAriaLabel = (isRewards: boolean, isMeal: boolean, meal: any) => {
    let label = `${itemsData.apply} ${itemsData.makeItAMeal}`;
    if (isRewards) label = adaDictionaryData.removeRewardAda;
    if (isMeal)
      label = `${itemsData.edit} ${commaSeparated(
        meal.components.map((item: Components) => item.name),
      )}`;
    return label;
  };
  return (
    <div className="cart__container">
      <div className="cart__header__button">
        <Button
          testAutoId={'keepShopping'}
          onClick={() => keepShopping()}
          className="cart__button"
        >
          {itemsData.keepShopping}
        </Button>
      </div>
      {cartItems.map((item: any, index: number) => {
        const options = commaSeparated(_get(item, 'ingredients', []));
        const noIngredients = commaSeparated(_get(item, 'noIngredients', []));
        const addonList = _get(item, 'addOnsList', []);
        return (
          <div key={index} className="cart__items">
            <div className="cart__items__head">
              <Heading
                className="cart__items--title"
                level="span"
                title={getCartItemName(item, options)}
                testAutoId={`cart-item-name-${index}`}
              />
              <Heading
                className="cart__items--price"
                testAutoId={`cart-item-price-${index}`}
                level="span"
                title={currencyPlacement(dictionaryLables.currency, item.price)}
              />
            </div>
            <div className="cart__items__body">
              {componentRenderCheck(multipleAndCheck(!_isEmpty(item.analytics),!item.analytics.isCookie),(
                <Heading
                  className="cart__items__description"
                  level="p"
                  title={ternaryCheck(item.isFountainDrink, item.name, options)}
                  testAutoId={`cart-item-des-${index}`}
                />
              ))}
              {componentRenderCheck(multipleAndCheck(!item.isRewards,!item.isSideOrDrink),(
                <a
                  role="button"
                  data-testauto-id={`cart-items-link-${index}`}
                  onClick={e => {
                    handleProductEdit(e, item.analytics.subCategory);
                  }}
                  className="cart__items__link"
                  href={window.subwayScript.navigation.getCartItemCustomizerUrl(
                    orderTypeDetails.storeData.storeId,
                    cartId,
                    ternaryCheck(item.isBundleItem,item.bundleId,item.cartItemId),
                    item.analytics?.categoryId,
                    orderViewModel.headerFooterLanguageCode,
                  )}
                  aria-label={`${EDIT} ${item.name}`}
                >
                  {itemsData.edit}
                </a>
              ))}
            </div>
            {componentRenderCheck(!_isEmpty(addonList),(
              <div className="cart__item__addonList">
                {ternaryCheck(!cultureLanguage.includes(orderViewModel.scLanguageCode),(
                  addonList.map((addonLists: any, indexs: number) => {
                    return (
                      <div className="cart__item__addonListItem" key={indexs}>
                        <Heading
                          level="p"
                          title={addonLists.optionName}
                          testAutoId={`add-on-list-name-${indexs}`}
                        />
                        <Heading
                          level="p"
                          title={addonLists.price}
                          testAutoId={`add-on-list-price-${indexs}`}
                        />
                      </div>
                    );
                  })
                ),(
                  <ul className="cart__item__listing">
                    {addonList.map((addonLists: any, indexs: number) => {
                      return (
                        <li key={indexs}>
                          <p className="cart__subitem__sublisting">
                            <Heading
                              level="span"
                              title={addonLists.optionName}
                              testAutoId={`add-on-list-name-${indexs}`}
                            />
                            <Heading
                              level="span"
                              title={addonLists.price}
                              testAutoId={`add-on-list-price-${indexs}`}
                            />
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                ))}
              </div>
            ))}
            {componentRenderCheck(noIngredients,(
              <Heading
                className="cart__items__unavailable"
                level="p"
                title={`${itemsData.unAvailableIngredients}${noIngredients}`}
                tabIndex={0}
                testAutoId={`no-ingredient-${index}`}
              />
            ))}

            {componentRenderCheck(item.outOfStockMIAM,(
              <Heading
                className="cart__items__unavailable"
                level="p"
                title={`${itemsData.unAvailableIngredients}${item.outOfStockMIAM}`}
                tabIndex={0}
                testAutoId={`out-of-stock-${index}`}
              />
            ))}
            {(item.isMealEligible ||
              (item.meal &&
                item.isKidsMeal &&
                cultureLanguage.includes(orderViewModel.scLanguageCode)) ||
              (item.meal && !isBAUPdp) ||
              item.isRewards) && (
              <div className={ternaryCheck(item.isMeal, 'cart__meal make__it__meal', 'cart__meal')}>
                {componentRenderCheck(multipleAndCheck(item.isMeal,!item.isUltimateBreakfast,!item.isKidsMeal),(
                  <Button
                    testAutoId={`remove-meal-${index}`}
                    onClick={() =>
                      setItemToRemoveHandler(
                        item.cartItemId,
                        true,
                        item.quantity,
                        false,
                        '',
                        item.isBundleItem,
                        _get(item, 'bundleId', ''),
                      )
                    }
                    className="cart__close"
                    icon={<SvgIconclose />}
                    aria-label={itemsData.removeMealOption}
                  />
                ))}
                <CardMenu
                  imageURL={getCardIcon(item.isMeal, item.isRewards)}
                  title={
                    ternaryCheck(item.isRewards,
                      item?.rewardData?.offerDescription,
                      cartComboName(isBAUPdp, item, itemsData, dictionaryLables))
                  }
                  description={ternaryCheck(
                    item.isRewards,
                    dictionaryLables.redeemed,
                    item.isMeal &&
                      commaSeparated(
                        item.meal.components.map((items: Components) =>
                          ternaryCheck(
                            items.upCharge > 0,
                            `${items.name} ${'\u2022'} ${currencyPlacement(
                              dictionaryLables.currency,
                              items.upCharge,
                            )}`,
                            items.name,
                          ),
                        ),
                      ),
                  )}
                  inStock={true}
                  showBorder={ternaryCheck(item.isRewards,item?.rewardData?.applied,'')}
                  testAutoIds={{ cardTitleTestAutoId: 'cart-mia-meal-text' }}
                >
                  <div className="card__link">
                    <Button
                      role="link"
                      testAutoId={`edit-apply-btn-${index}`}
                      onClick={() => {
                        handleApplyOrEdit(
                          window.subwayScript.navigation.getCartItemCustomizerUrl(
                            orderTypeDetails.storeData.storeId,
                            cartId,
                            item.cartItemId,
                            item.analytics?.categoryId,
                            orderViewModel.headerFooterLanguageCode,
                          ),
                          item.productId,
                          getItemClickedEvent(item.isRewards, item.isMeal, '', itemsData),
                          item.cartItemId,
                          _get(item, ['rewardData', 'offerId'], ''),
                          item.isKidsMeal,
                          item.isBundleItem,
                          _get(item, 'bundleId', ''),
                        );
                      }}
                      className="card__link__anchor primary__btn--link"
                      aria-label={getCardButtonAriaLabel(item.isRewards, item.isMeal, item.meal)}
                    >
                      {getItemClickedEvent(item.isRewards, item.isMeal, 'CTA', itemsData)}
                    </Button>
                  </div>
                </CardMenu>
                {componentRenderCheck(multipleAndCheck(item.isRewards,!item?.rewardData?.applied),(
                  <Heading
                    className="cart__items--error-message"
                    level="p"
                    title={dictionaryLables.cartErrorDescription}
                    testAutoId={`is-rewards-error-msg-${index}`}
                  />
                ))}
              </div>
            )}
            {item.meal &&
              !item.isMealEligible &&
              !item.isKidsMeal &&
              cultureLanguage.includes(orderViewModel.scLanguageCode) && (
                <div className="cart__item__addonList">
                  <Heading
                    level="h3"
                    className="cart__item__subTitle"
                    title={_get(dictionaryLables, ['drinksAndSides'], 'Drink and Side(s):')}
                    testAutoId={`item-faturedCombo-option-${index}`}
                  />
                  <ul className="cart__item__listing" data-testauto-id="drinkSideList">
                    {item.meal.components
                      .reduce((acc: any, items: any) => {
                        const existingIndex = acc.findIndex(
                          (item: any) => item.productID === items.productID,
                        );
                        if (existingIndex !== -1) {
                          const accCopy = [...acc];
                          getSides(existingIndex, accCopy, items);
                        } else {
                          acc.push({ ...items, quantity: 1 });
                        }
                        return acc;
                      }, [])
                      .map((groupedItem: Components, idx: number) => {
                        const quantity = groupedItem.quantity;
                        const combineGroupName = `${groupedItem.name}${ternaryCheck(
                          quantity > 1,
                          ` (${quantity})`,
                          '',
                        )}`;
                        return (
                          <li key={idx}>
                            <p className="cart__subitem__sublisting">
                              <span data-testauto-id={`add-on-drinkSide-${idx}`}>
                                {combineGroupName}
                              </span>
                              {groupedItem.upCharge > 0 && (
                                <span data-testauto-id={`add-on-upcharge-${idx}`}>
                                  {currencyPlacement(
                                    dictionaryLables.currency,
                                    groupedItem.upCharge,
                                  )}
                                </span>
                              )}
                            </p>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              )}
            {componentRenderCheck(!item.isRewards,(
              <div className="cart__buttons">
                <Button
                  testAutoId={`cart-buttons-minus-${index}`}
                  className={`cart__buttons--minus`}
                  onClick={() => {
                    if (item.quantity === 1) {
                      setItemToRemoveHandler(
                        item.cartItemId,
                        false,
                        item.quantity,
                        false,
                        '',
                        item.isBundleItem,
                        _get(item, 'bundleId', ''),
                      );
                    } else {
                      itemRemove(
                        item.cartItemId,
                        item.quantity,
                        false,
                        item.isBundleItem,
                        _get(item, 'bundleId', ''),
                      );
                    }
                  }}
                  icon={<SvgIconMinus />}
                  aria-label={adaDictionaryData.removeOneAda}
                />
                <Heading
                  level="span"
                  className="cart__buttons--text"
                  testAutoId={`item-qnt-${index}`}
                  title={item.quantity}
                />
                <Button
                  testAutoId={`cart-buttons-plus-${index}`}
                  className={`cart__buttons--plus`}
                  onClick={() =>
                    copyCartItem(
                      item.cartItemId,
                      cartId,
                      item.analytics?.categoryId,
                      item.masterProductId,
                      item.productId,
                      item.isBundleItem,
                      item.bundleId,
                    )
                  }
                  icon={<SvgIconPlus />}
                  aria-label={adaDictionaryData.addAda}
                />
                <Button
                  testAutoId={`item-remove-${index}`}
                  className={'cart__underline__btn remove'}
                  onClick={() =>
                    setItemToRemoveHandler(
                      item.cartItemId,
                      false,
                      item.quantity,
                      true,
                      '',
                      item.isBundleItem,
                      _get(item, 'bundleId', ''),
                    )
                  }
                  aria-label={`${adaDictionaryData.removeFromCartAda} ${item.name}`}
                >
                  {itemsData.remove}
                </Button>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default CartItems;
