import React from 'react';
import classnames from 'classnames';
import spinner from '../../../assets/RemoteOrder/icons/spinner.gif';
import './Spinner.scss';
import Image from '../Image/Image';
import Heading from '../Heading/Heading';

/**
 * Loading Spinner component
 * @param param0.message message to display while loading
 * @param param0.className custom class name
 * @returns react loader component
 */
const Spinner: React.FC<{ message?: string; className?: string }> = ({ message, className }) => (
  <div data-testid={'spinner'} className={classnames('spinner', className)}>
    <div className="spinner__container">
      <Image imageSources={spinner} alt="" className="spinner__image" />
      {message ? <Heading className="spinner__message" level="p" title={message} /> : null}
    </div>
  </div>
);

export default Spinner;
