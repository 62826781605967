import React from 'react';
import classNames from 'classnames';
import Heading from '../../Atoms/Heading/Heading';
import Image from '../../Atoms/Image/Image';
import { OUT_OF_STOCK, REMOVE_FAVORITE } from '../../../constants/App';
import Fav from '../../../assets/RemoteOrder/img/fav.svg';
import Plus from '../../../assets/RemoteOrder/img/plus.svg';
import Checkmark from '../../../assets/RemoteOrder/icons/filled-checkmark.svg';
import Button from '../../Atoms/Button/Button';
import _isEmpty from 'lodash/isEmpty';
import InnerHTML from '../../Atoms/SetInnerHTML/InnerHTML';
import { containsHTMLTags, currencyPlacement } from '../../../Utils/pageUtils';
import { multipleAndCheck, ternaryCheck } from '../../../Utils/WindowUtils';
export interface CardMenuProps {
  imageURL: [] | string;
  title: string;
  description?: string | React.JSX.Element;
  isFavourite?: boolean;
  inStock?: boolean;
  badgeTitle?: string;
  rewardType?: string;
  enableButton?: boolean;
  buttonHandler?: (value: React.SyntheticEvent<EventTarget>) => void;
  buttonText?: string;
  showBorder?: boolean;
  tosHandler?: {
    tosLabel: string;
    tosHandlerFn: React.Dispatch<React.SetStateAction<boolean>>;
  } | null;
  rewardText?: string;
  productName?: string;
  totalCount?: number;
  index?: number;
  linkText?: string;
  linkURL?: string;
  heartIconClickHandler?: any;
  children?: React.ReactNode;
  isDisabled?: boolean;
  cardButtonClassName?: string;
  testAutoIds?: {
    badgeTitleTestAutoId?: string;
    cardTitleTestAutoId?: string;
    cardDescriptionTestAutoId?: string;
    cardImageTestAutoId?: string;
  };
  showPlusIcon?: boolean;
  plusIconHandler?: any;
  showTickMark?: boolean;
  bundlePrice?: { maxPrice: number; minPrice: number };
  cardTextData?: { key: string, value: string }[];
}

const CardMenu: React.FC<CardMenuProps> = ({
  imageURL,
  title,
  description,
  isFavourite,
  inStock,
  badgeTitle,
  rewardType = '',
  enableButton = false,
  buttonText = '',
  tosHandler,
  rewardText,
  productName,
  totalCount,
  index,
  heartIconClickHandler,
  children,
  buttonHandler,
  isDisabled = false,
  cardButtonClassName = '',
  showBorder,
  testAutoIds = {},
  showPlusIcon = false,
  plusIconHandler,
  showTickMark,
  bundlePrice,
  cardTextData,
  ...props
}) => {
  const cardImg = classNames('card__img', {
    'card__img--fav': isFavourite,
  });
  const cardmenu = classNames('card__menu', {
    'cart__items--red-border': !_isEmpty(showBorder) && !showBorder,
    'cart__items--green-border': showBorder,
  });
  const rewardTextType = classNames('card__reward-type', {
    'card__reward-type--free-reward': rewardType == 'freeReward',
    'card__reward-type---mvd-reward': rewardType == 'mvdReward',
  });
  const cardPrimaryButton = classNames('primary__btn', {
    'secondary__btn rewardspage__card': cardButtonClassName == 'rewardsPage',
    card__button: cardButtonClassName != 'rewardsPage',
  });
  const isContainsHTMLTags = containsHTMLTags(description);
  const cardTextHandler = (val: string) => {
    const text = cardTextData?.filter((item) => { if (item.key == val) { return item.value } })
    return text?.[0]?.value
  }
  const handlePricingText = (minPrice: number, maxPrice: number) => {
    const featuredComboPricingType = (window as any).client.variation(
      (window as any).FF_COMBOS_BEFORE_AND_AFTER_PRICING,
      'SINGLE_PRICE',
    );
    if (featuredComboPricingType) {
      if (featuredComboPricingType == 'SINGLE_PRICE') {
        return `${cardTextHandler('from')}: ${currencyPlacement('$', minPrice)}`
      }
      if (featuredComboPricingType == 'NO_PRICE') {
        return ''
      }
      if (featuredComboPricingType == 'BEFORE_AND_AFTER_PRICE') {
        return <>{cardTextHandler('from')}: {currencyPlacement('$', minPrice)} <span className='card__bottom__oldPrice'> {currencyPlacement('$', maxPrice)}</span></>
      }
    }
  }
  const {
    badgeTitleTestAutoId,
    cardTitleTestAutoId,
    cardDescriptionTestAutoId,
    cardImageTestAutoId,
  } = testAutoIds;
  return (
    <div className={cardmenu}>
      {!inStock && <Heading level="p" className="card__out-of-stock" title={cardTextHandler('outOfStock') || OUT_OF_STOCK} />}
      {isFavourite && (
        <Button
          testAutoId="favorite__icon"
          className="favorite__icon"
          data-testid={`favorite-heart-icon-${title}`}
          onClick={e => heartIconClickHandler(e)}
          aria-label={`${REMOVE_FAVORITE}: ${productName}`}
        >
          <Fav />
        </Button>
      )}

      {showPlusIcon && (
        <Button
          testAutoId="plus__icon"
          className={`plus__icon ${ternaryCheck(multipleAndCheck(badgeTitle ,inStock) , 'plus__icon_badge' , '')}`}
          data-testid={`plus-icon-${title}`}
          onClick={plusIconHandler}
          aria-label={`${cardTextHandler('addToBag')}: ${title}`}
        >
          {showTickMark ? <Checkmark /> : <Plus />}
        </Button>
      )}

      {badgeTitle && inStock && (
        <Heading
          className="card__is-new"
          testAutoId={badgeTitleTestAutoId}
          level="h4"
          title={badgeTitle}
        />
      )}
      <div className="card__subcontainer">
        <div className={cardImg}>
          <Image
            className="card__image"
            imageSources={imageURL}
            alt={isFavourite ? productName : ''}
            testAutoId={cardImageTestAutoId}
          />
        </div>
        <div className="card__details">
          {rewardText && <span className={rewardTextType}>{rewardText}</span>}
          <InnerHTML
            Tag="h2"
            className="card__title"
            testAutoId={cardTitleTestAutoId}
            text={title}
          />
          {isContainsHTMLTags
            ? description && (
              <InnerHTML
                Tag="p"
                testAutoId={cardDescriptionTestAutoId}
                className="card__description"
                text={description}
              />
            )
            : description && (
              <Heading
                level="p"
                className="card__description"
                testAutoId={cardDescriptionTestAutoId}
                title={description}
              />
            )}
        </div>
        {bundlePrice?.maxPrice && (
          <div className="card__bottom">
            <Heading testAutoId={'card__bottom__price'} title={handlePricingText(bundlePrice.minPrice, bundlePrice.maxPrice)} className="card__bottom__price" level="p" />
          </div>
        )}
      </div>
      {enableButton && (
        <div className="card__action">
          <Button
            isDisabled={isDisabled}
            testAutoId="card-cta"
            aria-label={`${title} ${buttonText}`}
            className={cardPrimaryButton}
            onClick={buttonHandler}
          >
            {buttonText}
          </Button>
          {tosHandler && (
            <Button
              className="card__tos"
              testAutoId="card-tos"
              onClick={() => tosHandler?.tosHandlerFn(true)}
            >
              {tosHandler?.tosLabel}
            </Button>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default CardMenu;
