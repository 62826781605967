import React, { useEffect } from 'react';
import { WARNING_MESSAGE } from '../../../constants/AnalyticsConstants';
import useStore from '../../../store/useStore';
import Button from '../../Atoms/Button/Button';
import { CreateCartInterface } from '../../Interfaces/CreateCartErrorsInterface';
import Modal from '../Modal/Modal';

const CreateCartErrors: React.FC<CreateCartInterface> = ({
  showModal,
  modalData,
  closePopup,
  showClose,
  okText,
  name,
}) => {
  const { setAnalyticsProducts, setAttemptTracker } = useStore();
  useEffect(() => {
    setAnalyticsProducts({
      warningMessage: modalData.message,
      section: name,
      warningMessageUI :1,
      virtualName: 'select a new restaurant modal'
    });
    setAttemptTracker([{ name: WARNING_MESSAGE }]);
  }, []);
  const onClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setAnalyticsProducts({
      clickActionName: 'find another restaurant',
      virtualName: 'select a new restaurant modal'
    });
    setAttemptTracker([{ name: 'modal_cta', isClickable: true }]);
  };

  return (
    <Modal
      isShown={showModal}
      title={modalData.title}
      description={modalData.message}
      onButtonClick={closePopup}
      isClosebtn={showClose}
    >
      {modalData.url ? (
        <a href={modalData.url} onClick={e => onClick(e)} className="popup__button">
          {modalData.buttonText}
        </a>
      ) : (
        <Button className="popup__button" testAutoId='ok-cta' onClick={closePopup}>
          {okText}
        </Button>
      )}
    </Modal>
  );
};

export default CreateCartErrors;
