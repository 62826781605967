import React, { useState } from 'react';
import { currencyPlacement } from '../../../Utils/pageUtils';
import Button from '../../Atoms/Button/Button';
import Heading from '../../Atoms/Heading/Heading';
import PriceWithLabel from '../../Templates/OrderManagement/OrderSummary/PriceWithLabel';
import InformationIcon from '../../../assets/RemoteOrder/icons/Information.svg';
import './OrderPaymentBreakups.scss';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

interface OrderPaymentBreakupsProps {
  dictionaryLables: any;
  summaryData: any;
  isOrderConfirmationPage?: boolean;
}

const OrderSummaryPrice: React.FC<{ title: string; currency: string; price: number;
  innerPriceTitleTestAutoId?:string;innerPriceAmountTestAutoId?:string; showInfo?: boolean; showInfoText?: string; handleClick?: any }> = ({
  title,
  currency,
  price,
 innerPriceTitleTestAutoId,
 innerPriceAmountTestAutoId,
 showInfo = false,
 showInfoText = "",
 handleClick
}) => {
  return (
    <div className="order-summary__price">
      <Heading level="span" testAutoId={innerPriceTitleTestAutoId} title={title} />
      {showInfo && !_isEmpty(showInfoText) &&
        <Button
          className="caDriverBenifitsIcon__button"
          onClick={handleClick}
          aria-controls="icon"
          aria-label={title}
          testAutoId={'ca-driver-benefits'}
        >
          <InformationIcon />
        </Button>
      }
      <Heading level="span" testAutoId={innerPriceAmountTestAutoId} title={currencyPlacement(currency, price)} />
    </div>
  );
};

const OrderPaymentBreakups: React.FC<OrderPaymentBreakupsProps> = ({
  dictionaryLables,
  summaryData,
  isOrderConfirmationPage = false,
}) => {
  const {
    yourTotal,
    currency,
    deals,
    subtotal,
    deliveryFee,
    giftCards,
    rewards,
    sandwichArtistTip,
    smallOrderFee,
    serviceFee,
    youPay,
    driverTip: driveTipText,
    prop22: caProp22,
    caDriverBenefits,
  } = dictionaryLables;
  const {
    yourTotal: yourTotalPrice,
    promotionAmount,
    delivery,
    tax,
    service,
    smallOrder,
    certificateAmount,
    supportsSATip,
    saTip,
    subtotal: subtotalPrice,
    giftCardsPrice,
    youPay: youPayPrice,
    totals,
  } = summaryData;
  const [caDriverIcon, setcaDriverIcon] = useState(false);

  const handleCADriver = () => {
    setcaDriverIcon(!caDriverIcon)
  }

  const caDriverBenefit = _get(totals, 'deliveryCharges.fees.prop22', 0);
  return (
    <div className="order-summary__prices">
      <PriceWithLabel
        label={_get(dictionaryLables, 'ordertotal', yourTotal)}
        currency={currency}
        price={yourTotalPrice}
        priceTitleTestAutoId='order-your-total-title'
        priceAmountTestAutoId='order-your-total-amount'
      />

      {promotionAmount > 0 && (
        <PriceWithLabel
          label={_get(dictionaryLables, 'totalsavings', deals)}
          currency={currency}
          price={promotionAmount}
          isShowMinus={true}
          priceTitleTestAutoId='order-promotion-title'
          priceAmountTestAutoId='order-promotion-amount'
        />
      )}

      {certificateAmount > 0 && (
        <PriceWithLabel
          label={rewards}
          isShowMinus={true}
          currency={currency}
          price={certificateAmount}
          priceTitleTestAutoId='order-certificate-title'
          priceAmountTestAutoId='order-certificate-amount'
        />
      )}

      <PriceWithLabel
        label={subtotal}
        currency={currency}
        priceTitleTestAutoId='order-sub-total-title'
        priceAmountTestAutoId='order-sub-total-amount'
        price={subtotalPrice} />

      {delivery > 0 &&
      <PriceWithLabel
      label={deliveryFee}
      currency={currency}
      price={delivery}
      priceTitleTestAutoId='order-delivery-title'
      priceAmountTestAutoId='order-delivery-amount'
      />}

          {!_isEmpty(tax) &&
            tax.map((individualTax: any,index:number) => (
              <OrderSummaryPrice
                key={individualTax.amount}
                title={individualTax.description}
                currency={currency}
                price={individualTax.amount}
                innerPriceTitleTestAutoId={`order-sub-tax-title-${index}`}
                innerPriceAmountTestAutoId={`order-sub-tax-amount-${index}`}
              />
            ))}
          {caDriverBenefit > 0 && (
            <>
              <OrderSummaryPrice
                title={caDriverBenefits}
                currency={currency}
                price={caDriverBenefit}
                innerPriceTitleTestAutoId='order-driver-title'
                innerPriceAmountTestAutoId='order-driver-amount'
                showInfo={true}
                showInfoText={caProp22}
                handleClick={handleCADriver}
              />

              {caDriverIcon && <Heading level="span" className="ca-driver__text" testAutoId='ca-driver-benefits-text'  title={caProp22} />}
            </>
          )}
          {service > 0 && (
            <OrderSummaryPrice
            title={serviceFee}
            currency={currency}
            price={service}
            innerPriceTitleTestAutoId='order-service-title'
            innerPriceAmountTestAutoId='order-service-amount'
            />
          )}
          {smallOrder > 0 && (
            <OrderSummaryPrice
            title={smallOrderFee}
            currency={currency}
            price={smallOrder}
            innerPriceTitleTestAutoId='order-small-order-title'
            innerPriceAmountTestAutoId='order-small-order-amount'
            />
          )}      

      {supportsSATip && (
        <PriceWithLabel
        label={sandwichArtistTip}
        currency={currency}
        price={saTip}
        priceTitleTestAutoId='order-support-satip-title'
        priceAmountTestAutoId='order-support-satip-amount'
        />
      )}

      {giftCardsPrice > 0 && (
        <PriceWithLabel
          label={giftCards}
          isShowMinus={true}
          currency={currency}
          price={giftCardsPrice}
          priceTitleTestAutoId='order-gift-card-title'
          priceAmountTestAutoId='order-gift-card-amount'
        />
      )}
      {_get(totals, 'deliveryCharges.driverTip', null) > 0 && (
        <PriceWithLabel
          label={driveTipText}
          currency={currency}
          price={_get(totals, 'deliveryCharges.driverTip', 0)}
          priceTitleTestAutoId='order-diver-tip-title'
          priceAmountTestAutoId='order-driver-tip-amount'
        />
      )}

      <div className="order-summary__price">
        <Heading
          level="span"
          className="order-summary__price--dark"
          title={_get(dictionaryLables, 'total', youPay)}
          testAutoId='order-you-pay-title'
        />
        <Heading
          level="span"
          className="order-summary__price--dark"
          title={currencyPlacement(currency, youPayPrice)}
          testAutoId='order-you-pay-amount'
        />
      </div>
    </div>
  );
};

export default OrderPaymentBreakups;
