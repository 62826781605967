import React from 'react';
import { currencyPlacement } from '../../../../Utils/pageUtils';
import Heading from '../../../Atoms/Heading/Heading';

interface PriceWithLabelProps {
  label: string;
  currency: string;
  price: number;
  isShowMinus?: boolean;
  priceTitleTestAutoId?:string;
  priceAmountTestAutoId?:string;
}

const PriceWithLabel: React.FunctionComponent<PriceWithLabelProps> = ({
  label,
  currency,
  price,
  isShowMinus = false,
  priceTitleTestAutoId,
  priceAmountTestAutoId,
}) => {
  return (
    <div className="order-summary__price">
      <Heading level="span" title={label} testAutoId={priceTitleTestAutoId} />
      <Heading level="span" title={currencyPlacement(currency, price, isShowMinus)}  testAutoId={priceAmountTestAutoId}/>
    </div>
  );
};

export default PriceWithLabel;
