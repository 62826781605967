import {getLocatorUrl,getPickupLocatorUrl,getLocationObject,getPromoUrl,getOfferUrl,getDeeplinkUrl,} from '../Utils/componentUtils'
import Deal from '../components//Interfaces/DealInterface';
import {DEAL_NATIONAL, NON_BONUS} from '../constants/App'
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';

export const navigateToPickUpLocatorWithDeal = (deal:Deal)=>{
    window.location.href = getPickupLocatorUrl(deal)
}

export const navigateToMenu = ()=>{
    window.location.href = window.subwayScript.navigation.getMenuUrl(window.orderViewModel.store.id);
}

export const navigateToLocator = ()=>{
    window.location.href = getLocatorUrl();
}

export const navigateToDealDeepLinkUrl = (deal:Deal)=>{
    window.location.href = getDeeplinkUrl(deal);
}

export const navigateToLocatorWithDeal = (deal:Deal)=>{
    const locationObject = deal.loyaltyDetail && getLocationObject(deal)
    const url = _isEqual(deal.cardType, DEAL_NATIONAL) || _isEqual(deal.cardType, NON_BONUS)
    ? getPromoUrl(deal.promoPLU, deal.promoId, deal.promoCode, '')
    : getOfferUrl(locationObject);
    window.location.href = url
}
export const navigateToLocatorWithDealPickup = (deal:Deal)=>{
    const locationObject = deal.loyaltyDetail && getLocationObject(deal)
    const url = _isEqual(deal.cardType, DEAL_NATIONAL) || _isEqual(deal.cardType, NON_BONUS)
    ? getPromoUrlPickup(deal.promoPLU, deal.promoId, deal.promoCode, '')
    : getOfferUrlPickup(locationObject);
    window.location.href = url
}
export const navigateToBamkoUrl = (url: string, userAgent: boolean) => {
    if(userAgent) {
        return window.open(url, '_top', 'noopener');
    }
    return window.open(url, '_blank', 'noopener');
};

export const navigateToRewardsActivity = ()=>{
    window.location.href = window.subwayScript.navigation.getRewardsActivityTestUrl();
}

export const showBannerMessage = (type: string, message: string, get_Data?: any, link?: any, element?: string, customClass?: string, callback?: any) => {
    if (!(_isEmpty(get_Data))) {
        const { autohide, closable, infoIcon, paypalCloseClick } = get_Data;
        (window as any).getBannerMessage(type, message, autohide, closable, infoIcon, paypalCloseClick, link, element, customClass, callback);
    }
};
export const getOfferUrlPickup = (locationObject = {}) => {
    return window.subwayScript.navigation.getLocatorUrl() + '?offer=' + JSON.stringify(locationObject);
};
export const getPromoUrlPickup = (promoPLU: string|null, promoId: string|null, promoCode: string|null, intcmp = '') => {
    const url = window.subwayScript.navigation.getLocatorUrl();
    const promoURL =
        '?promoPLU=' + promoPLU + '&promoId=' + promoId + '&promoCode=' + promoCode + intcmp;
    return url + (promoId ? promoURL : '');
};

export const userAgentDetails =  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const ternaryCheck = (ternaryExpression: boolean , successReturn: any , failurReturn: any) => {
    if (ternaryExpression) {
        return successReturn;
    }
        return failurReturn;
}

export const multipleAndCheck = (...conditions: any) => {
    if (_isEmpty(conditions)) {
        return false;
    }
    for (const condition of conditions) {
        if (!condition) {
            return false;
        }
    }
    return true;
}

export const multipleOrCheck = (...conditions: any) => {
    if (_isEmpty(conditions)) {
        return false;
    }
    for (const condition of conditions) {
        if (condition) {
            return true;
        }
    }
    return false;
}

export const componentRenderCheck = (isRender: boolean, renderComponent: React.JSX.Element) => {
    if (isRender) {
        return renderComponent;
    }
}

export const getSides = (eIndex: any, acc_array: any, side_item: any) => {
    if(acc_array[eIndex].id === side_item.id){
      return acc_array[eIndex].quantity += 1;
    }else{
      return acc_array[eIndex].name += `, ${side_item.name}`;
    }
}

export const getComboListDetails = (existingIndex: any, acc: any, item: any) => {
    if (existingIndex !== -1) {
        acc[existingIndex].quantity += 1;
    } else {
        acc.push({ ...item, quantity: 1 });
    }
    return acc;
}
