import React, { useEffect } from 'react';
import Modal from 'react-modal';
import Slider from 'react-slick';
import Button from '../Atoms/Button/Button';
import IngredientCard from '../IngredientCard/IngredientCard';
import { priceCaloriesConverter, addToBagName } from '../../Utils/componentUtils';
import Heading from '../Atoms/Heading/Heading';
import './UpSellOverlay.scss';
import SvgIconClose from '../../assets/RemoteOrder/icons/CloseWithoutCircle.svg';
import { DONE, NO_THANKS_CTA } from '../../constants/AnalyticsConstants';

const settings = {
  speed: 500,
  slidesToShow: 1.6,
  slidesToScroll: 1,
  infinite: false,
  beforeChange: oldIndex => {
    const nextSlide = document.querySelector(`[data-index="${oldIndex}"]`);
    setTimeout(() => {
      nextSlide.setAttribute('aria-hidden', 'false');
    });
  },
};

const UpSellOverlay = ({
  title,
  combo,
  Cals,
  modalIsOpen,
  closeModal,
  selectCombo,
  buttonText,
  customizerData,
  onOutSideClick = true,
  isClosebtn = false,
  isButtonText = false,
  isPosition = false
}) => {
  const afterOpenModal = () => {
    document.querySelector(".ReactModal__Content").focus()
  }
  useEffect(() => {
      setTimeout(() => {
        const elements = document.querySelectorAll('.slick-slide');
        console.log(elements);
        elements.forEach(element => {
          element.setAttribute('aria-hidden', 'false');
        });
        const prevData = document.querySelector('.slick-prev');
        prevData && prevData.setAttribute('data-testauto-id', 'slick-prev-arrow')
        const nextData = document.querySelector('.slick-next');
        nextData && nextData.setAttribute('data-testauto-id', 'slick-next-arrow')
        const upsellElement = document.querySelectorAll('.cardcomponent');
        upsellElement?.forEach(element => {
          element.removeAttribute('tabindex');
          element.removeAttribute('role');
          element.removeAttribute('aria-checked');
        });
      });
  });
  return (
    <Modal
      data-testauto-id={'react-modal__content'}
      appElement={document.getElementById('root-react-pr')}
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={() => onOutSideClick && closeModal('cancel')}
      className="react-modal__content"
      contentLabel={title}
      shouldReturnFocusAfterClose={false}
      shouldFocusAfterRender={false}
    >
      <Heading className="react-modal__title" title={title} level="h2" testAutoId='upsell-title' />
      {isClosebtn &&
        <Button
          className="modalPopup__closeBtn"
          onClick={closeModal}
          icon={<SvgIconClose />}
          aria-label="close"
        ></Button>
      }
      <Slider className="react-modal__itemscontainer" {...settings}>
        {combo &&
          combo.map(
            ({
              id,
              name,
              description,
              price,
              calorie,
              imageUrl,
              inStock,
              isSelected,
              isAddtoCart,
              isUpdated,
              hasCombo,
            }, comboIndex) => (
              <IngredientCard
                key={id}
                id={id}
                className="react-modal__items"
                ingredientName={name}
                description={description}
                ingredientCalories={priceCaloriesConverter(
                  price,
                  calorie,
                  Cals,
                  hasCombo,
                  customizerData.upTo,
                )}
                onIngrdientClick={() =>
                  selectCombo(id, isAddtoCart, addToBagName(isAddtoCart, isUpdated, customizerData))
                }
                imageUrl={imageUrl}
                inStock={inStock}
                isSelected={isSelected}
                iconButton={false}
                isChoiceButtonEnabled={true}
                btnName={addToBagName(isAddtoCart, isUpdated, customizerData)}
                isDisabled={isUpdated}
                hasCombo={hasCombo}
                isUpsellModal={true}
                isPosition={isPosition}
                uniqueTestId={`upsell-${comboIndex}`}
              />
            ),
          )}
      </Slider>
      <div className="react-modal__buttons">
        <Button
          testAutoId={'close-Modal-btn'}
          className="primary__btn primary__btn--link small-only"
          onClick={() => closeModal(isButtonText ? DONE : NO_THANKS_CTA)}
        >
          {buttonText}
        </Button>
      </div>
    </Modal>
  );
};

export default UpSellOverlay;
