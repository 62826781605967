import React from 'react';
import Button from '../../../Atoms/Button/Button';
import Heading from '../../../Atoms/Heading/Heading';
import Modal from '../../../Molecules/Modal/Modal';
import './VehicleSelection.scss';

interface VehicleSelectionInterface {
  data: any;
  setCurbsidePopup: (value: boolean) => void;
  setSelectedVehicle: (value: string) => void;
  setSelectedColor: (value: string) => void;
  selectedVehicle: string;
  selectedColor: string;
  refetchOrderManagement: (isCurbsidePopup?: boolean, continueCta?: string) => void;
}

const VehicleSelection: React.FunctionComponent<VehicleSelectionInterface> = ({
  data,
  setCurbsidePopup,
  setSelectedVehicle,
  setSelectedColor,
  selectedVehicle,
  selectedColor,
  refetchOrderManagement,
}) => {
  const { vehicleTypes, vehicleColors, selectColor, selectVehicle, requiredInfo, continuePayment } =
    data;

  return (
    <Modal
      componentParentClass='vehicle_selection_popup'
      data-testauto-id={'vehicle-selection'}
      isClosebtn={true}
      title={data.crubsidePopupTitle}
      onButtonClick={() => setCurbsidePopup(false)}
    >
      <div className="vehicle__selection">
        {requiredInfo && <Heading className="vehicle__subTitle" level="p" title={requiredInfo} />}
        {selectVehicle && <Heading className="vehicle__title" level="h2" title={selectVehicle} />}
        <div role="radiogroup" className="vehicle__selection__button">
          {vehicleTypes &&
            vehicleTypes.map((vehicleType: any) => {
              return (
                <React.Fragment key={vehicleType.name}>
                  <Button
                    testAutoId={`${vehicleType.name}`}
                    key={vehicleType.name}
                    onClick={() => setSelectedVehicle(vehicleType.name)}
                    role="radio"
                    aria-checked={selectedVehicle == vehicleType.name}
                    className={
                      selectedVehicle == vehicleType.name
                        ? `vehicle__color selected`
                        : `vehicle__color`
                    }
                  >
                    <img className="vehical__image" src={`${vehicleType.image}`} alt="" />
                    <span>{vehicleType.name}</span>
                  </Button>
                </React.Fragment>
              );
            })}
        </div>
        {selectColor && <Heading className="vehicle__title" level="h2" title={selectColor} />}
        <div role="radiogroup" className="vehicle__selection__button">
          {vehicleColors &&
            vehicleColors.map((vehicleColor: any) => {
              return (
                <React.Fragment key={vehicleColor.name}>
                  <Button
                    testAutoId={`${vehicleColor.name}`}
                    key={vehicleColor.name}
                    onClick={() => setSelectedColor(vehicleColor.name)}
                    role="radio"
                    aria-checked={selectedColor == vehicleColor.name}
                    className={
                      selectedColor == vehicleColor.name
                        ? `vehicle__color selected`
                        : `vehicle__color`
                    }
                  >
                    <img className="vehical__image" src={`${vehicleColor.image}`} alt="" />
                    <span>{vehicleColor.name}</span>
                  </Button>
                </React.Fragment>
              );
            })}
        </div>
      </div>
      <div className="vehicle__button">
        {continuePayment && (
          <Button
            testAutoId={'continuePayment-btn'}
            isDisabled={(selectedVehicle && selectedColor) == ''}
            onClick={() => selectedVehicle && selectedColor && refetchOrderManagement(true)}
            className="vehicle__continue__button"
            aria-disabled={!(selectedVehicle && selectedColor)}
          >
            {continuePayment}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default VehicleSelection;
